
/*   -=========== Fonts ===========-   */

@import url('https://fonts.googleapis.com/css?family=Didact+Gothic&subset=cyrillic');

@font-face {
  font-family: 'rouble';
  src: url('/i/fonts/rouble-webfont.woff2') format('woff2'),
       url('/i/fonts/rouble-webfont.woff') format('woff');
}
