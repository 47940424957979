.ya-share2-hide-text {
  .ya-share2 {
    display: inline-block;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;

    &:before {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
    }
  }

  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
  }
}