#send-articul--popup-background {
  display: none;
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 999998;
}

.send-articul--popup {
  display: none;

  &-block {
    position: fixed;
    top: 20%;
    left: 50%;
    padding: 50px;
    width: 360px;
    margin-left: -200px;
    background: #fff;
    border: 1px solid orange;
    border-radius: 4px;
    z-index: 999999;
    opacity: 1;

    @media $small-mobile {
      top: calc((100% - 290px) / 2);
      left: 5%;
      width: calc(90% - 50px);
      height: 220px;
      margin: auto;
      border: none;
      border-radius: 10px;
      padding: 30px 25px 40px;
    }

    & > .send-articul--popup-sharing {
      padding-top: 20px;
  
      @media $small-mobile {
        padding: 0;
        margin-top: 20px;
        color: #787878;
        font-size: 20px;
      }
    }

    & > .product-card--sharing {
      position: unset;
      margin-top: 10px;
      padding: 0 15px;
      transform: unset;
      border-radius: 0;

      @media $small-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        width: 100%;
        padding: 10px 0;
        border-width: 1px;
        border-radius: 10px;

        a::before {
          width: 35px;
          height: 35px;
        }
      }
    }

    & > .product-card--sharing li {
      margin-left: 0;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;

    @media $small-mobile {
      top: 20px;
      right: 25px;
    }
  }

  &-title {
    padding-bottom: 29px;

    @media $small-mobile {
      font-size: 22px;
      padding: 0;
    }
  }

  &-copy {
    width: 100%;
    padding: 10px;
    background-color: #97cdfa;
    color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

    @media $small-mobile {
      margin-top: 35px;
      height: 45px;
      font-size: 20px;
    }

    &:hover {
      background-color: #5ca5e1;
      color: #fff;
    }
  }

  &-textarea {
    margin-top: 8px;
    padding: 5px 0 10px 0;
    width: 100%;
    height: 30px;
    border: 1px solid #bdbdbd;
    white-space: nowrap;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    resize: none;
  }

  &-link {
    display: none;
    padding-top: 20px;
    width: 100%;
  }
}

.product--remove-button {
  display: inline-block;
  vertical-align: middle;
  width: 38px;
  height: 35px;
  font-size: 0;
  background: url("/i/trash-mobile.svg") no-repeat;

  @media $small-mobile {
    display: block;
    width: 50px;
    height: 50px;
    transform: none;
    background: url(/i/close-sharing.svg) no-repeat;
    background-size: contain;
    float: right;

    &:before,
    &:after {
      display: none;
    }
  }
}

.send-articul--popup-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  & > input[type=email] {
    margin-bottom: 10px;
    padding: 5px 0 5px 0;
    border: 1px solid #bdbdbd;
  }

  & > button[type=submit] {
    padding: 10px;
    background-color: #97cdfa;
    color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: #5ca5e1;
      color: #fff;
    }
  }
}

input[name="copyemail"] {
  display: block;
  width: 2px;
  height: 2px;
  margin-bottom: -2px;
  border: none;
  padding: 0;
  opacity: 0.01;
}