
/*   -=========== Spoilers ===========-   */

@media $mobile {
  .mobile-spoiler-header {
    padding-top: 12px;
    padding-bottom: 15px;
    border-top: 2px solid #d9dfe7;
    border-bottom: 2px solid #d9dfe7;
    position: relative;
    text-align: left !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0 !important;

    .wrapper & {
      margin-left: -10px;
      margin-right: -10px;
    }

    span {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &:before {
      display: none !important;
    }

    &:after {
      content: '';
      display: block;
      width: 5px; height: @width;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(-45deg) translate(-20%, -60%);
      position: absolute;
      right: 30px; top: 50%;

      .mobile-spoiler.is-collapsed & {
        transform: rotate(135deg) translate(-90%, 0);
        margin-right: 10px;
      }
    }
  }

  .mobile-spoiler-body {
    overflow: hidden;
    transition: all .25s ease;

    &:not(.wrapper) {
      padding-left: 30px;
      padding-right: 30px;

      .wrapper & {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .mobile-spoiler:not(.is-collapsed) & {
      padding-top: 30px;
      margin-bottom: 25px;
      max-height: 5000px;
      opacity: 1;
    }

    .mobile-spoiler.is-collapsed & {
      max-height: 0;
      opacity: 0;
    }
  }

  .mobile-spoiler.is-collapsed + .mobile-spoiler {
    .mobile-spoiler-header {
      border-top: none;
    }
  }
}
