.preview-slider {
  display flex;
  justify-content space-between;
  padding-left 10px;
  padding-right 10px;
  margin-top 10px;
  margin-bottom 5px;

  &__img-wrapper {
    aspect-ratio: 5 / 6;
  }

  &__img-container {
    width: 100%;
    display inline-grid;
  }

  &__container {
    box-sizing border-box;
    width 100%;
    padding 15px 10px 5px;
    position absolute;
    left 0;
    top 0;
    z-index 6;
    cursor pointer;
    display block !important;

    @media $small-mobile {
      padding-top 12px;
    }
  }

  &__item {
    flex 1 1 0;
    position relative;

    &:first-child {
      .preview-slider__mark {
        background $blue;
      }
    }
  }

  &__mark {
    background $white;
    border-radius 3px;
    transition background .25s ease;
    height 100%;

    &--wrap {
      height 2px;
      padding-left 2px;
      padding-right 2px;
    }
  }

  &__activator {
    height 50px;
    position absolute;
    width 100%;

    @media only screen and (max-width: $portrait-tablet-breakpoint){
      display none;
    }
  }

  &__img {
    left: 0 !important;
    top: 0 !important;
    transform none !important;
    position relative !important;
    max-height 365px !important;
    //width inherit !important;
  }
}

_::-webkit-full-page-media, _:future, :root .preview-slider {
  margin-top 5px;
}

_::-webkit-full-page-media, _:future, :root .preview-slider__container {
  padding-top 0;
}