
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Breadcrumbs

.breadcrumbs {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.4rem;

  @media $small-handheld {
    padding-top: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    @media $small-handheld {
      margin-bottom: 1em;
    }

    @media $mobile {
      line-height: 2;
    }
  }

  li + li {
    margin-left: 50px;

    @media $mobile {
      margin-left: 30px;
    }

    &:before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: 8px; height: 8px;
      border-top: 2px solid #828282;
      border-right: 2px solid #828282;
      border-radius: 1px;
      position: absolute;
      right: 100%; top: 50%;
      transform: rotate(45deg) translateY(-50%);
      margin-right: 24px;

      @media $mobile {
        margin-right: 15px;
      }
    }
  }

  a {
    text-decoration: none;
    color: #828282;
    span {
        color: #828282;
        &:hover {
              color: $black;
            }
    }
  }
}

.breadcrumbs--back {
  position: relative;
  padding: 9px 40px 11px;
  margin-top: -9px;
  margin-bottom: -11px;
  cursor: pointer;
  line-height: 2;

  @media $mobile {
    padding: 5px 20px 7px 30px;
  }

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%; height: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 30px;
    position: absolute;
    left: 0; top: 0;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 8px; height: 8px;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    border-radius: 1px;
    position: absolute;
    left: 20px; top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    margin-right: 24px;
    margin-top: -1px;
  }
}


// Main menu

.colors-menu-prototype {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .item {
    margin: 18px 16px 0 0;
  }

  .item a {
    display: block;
    width: 30px; height: @width;
    border-radius: 50%;
    transition: opacity .25s ease;

    &:hover {
      opacity: .7;
    }
  }
}

.main-menu {
  /* background: $light-gray; */
  line-height: 55px;
  /* position: relative; */
  z-index: 1001;

  /* grid-row: 2 / 3;
  grid-column: 2 / -2; */
  grid-area: menu;

  /* .wrapper { */
    @extend .menu, .justify-menu;

    > ul > li > a {
      display: block;
      height: @line-height;
      line-height: @line-height - 5px;
      position: relative;
      z-index: 10;
      color: #828282;

      /* &:before {
        content: '';
        display: block;
        width: 100%; height: 100%;
        padding: 0 45px;
        background: rgba($white, .7);
        position: absolute;
        left: -45px; top: 0;
        z-index: -1;
        opacity: 0;
        transition: opacity .25s ease;

        @media $notebook {
          padding: 0 10px;
          left: -10px;
        }
      } */
    }

    > ul > li.is-hovered > a {
      color: #4f4f4f !important;
    }

    /* > ul > li.is-hovered > a:before {
      opacity: 1;
    } */
  /* } */

  a {
    @media $not-notebook {
      font-size: 2rem;
    }

    @media $notebook {
      font-size: 1.6rem;
    }
  }

  .has-dropdown {
    > a {
      padding-right: 26px;
      position: relative;
      color: $black;

      &:after {
        content: '';
        display: block;
        width: 9px; height: 9px;
        border-left: 2px solid $black;
        border-bottom: 2px solid $black;
        position: absolute;
        top: 50%; right: 0;
        transform: rotate(-45deg) translateY(-50%);
        margin-top: -4px;
        transition: all .25s ease;
      }
    }
  }

  .catalogue-item > a {
    color: #000
  }

  .dropdown {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;
    position: absolute;
    left: 50%; top: 100%;
    transform: translateX(-50%);
    z-index: 10;
    /* background: $white; */
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); */
    width: 100%;
    max-width: $wrapper-width;
    line-height: 1.2;
    box-sizing: border-box;

    @media $less-than-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    .wrapper {
      padding-top: 20px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(5, auto);
      grid-auto-flow: column;
      align-items: stretch;
      grid-column-gap: 35px;
      grid-row-gap: 20px;
      padding-bottom: 35px;
      padding-left: 35px;
      padding-right: 35px;
      background: #edf3f9;
      box-shadow: 0 4px 20px rgba(#000, .15);

      ul {
        padding: 15px 20px 20px;
        background: $white;
        box-shadow: 0 4px 20px rgba(#000, .15);
        border-radius: 20px;
      }
    }
  }


  .has-dropdown:not(.catalogue-item) {
    position: relative;

    .dropdown {
      left: 50%;
      width: auto;
      white-space: nowrap;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -10px;

      .wrapper {
        display: block;
        padding-top: 10px;
        padding-bottom: 15px;
      }

      li + li {
        margin-top: .5em;
      }

      a {
        transition: color .25s ease;
      }

      a:hover {
        color: $gray;
      }
    }

    &.is-hovered .dropdown {
      padding-top: 10px;
      padding-bottom: 15px;
    }
  }

  .has-dropdown.is-hovered {
    > a:after {
      transform: rotate(135deg);
    }

    .dropdown {
      max-height: 1200px;
      opacity: 1;
      /* padding-top: 35px; */
      /* padding-bottom: 35px; */
      padding-bottom: 15px;
    }
  }

  .dropdown--column {
    /* padding-right: 35px; */
    /* flex-basis: 'calc(20% - %s)' % @padding-right; */
    /* padding-top: 35px; */
    grid-column-end: span 1;
    grid-row-end: span 2;
    display: flex;
    flex-direction: column;

    > ul {
      flex: 1;
    }

    li + li {
      margin-top: .25em;
    }

    li a {
      font-size: 2rem;
      transition: color .25s ease;

      @media $notebook {
        font-size: 1.7rem;
      }

      &:hover {
        color: $gray;
      }
    }
  }

  .dropdown--column_colors,
  .dropdown--column_price {
    grid-row-end: span 1;
  }

  .dropdown--column_colors {
    ul {
      line-height: 0;
      font-size: 0;
      display: block;
    }

    .item {
      display: inline-block;
      vertical-align: top;
      margin: 0 4px 4px 0;
    }
  }

  .dropdown--caption {
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    position: relative;

    @media $notebook {
      font-size: 1.7rem;
    }
  }

  .dropdown--column_multicolumn {
    /* flex-basis: 100%; */
    grid-column: 1 / 5;
    grid-row: 5 / 6;
    padding-right: 0;

    ul {
      width: 80%;
      display: flex;
      flex-wrap: wrap;

      li {
        box-sizing: border-box;
        width: 25%;
        padding-right: 20px;
      }
    }
  }

  .dropdown--column_colors {
    @extend .colors-menu-prototype;
  }

  .dropdown--more-link {
    display: inline-block;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1;
    white-space: nowrap;
    margin-left: 20px;
    border-bottom: 1px solid rgba($black, .5);
    position: absolute;
    left: 100%; top: 50%;
    transform: translateY(-50%);

    @media only screen and (max-width: 1500px) {
      font-size: 1.5rem;
      margin-top: .3rem;
    }

    @media $notebook {
      margin-top: .2rem;
      margin-left: 10px;
    }
  }

  div.dropdown--catalogue-link {
    text-align: center;
    margin-top: 10px;
    /* margin-top: 40px; */

    a {
      @extend .btn, .blue-btn, .rounded-btn;
      text-transform: uppercase;
      font-size: 1.5rem;
      padding: 0 55px 0 25px;
      position: relative;
      line-height: 1.2;
      padding-top: 7px;
      padding-bottom: 10px;
      box-shadow: 0 4px 20px rgba(#000, .25);

      &:after {
        content: '';
        display: block;
        background: url(/i/right-arrow-icon.svg) no-repeat 50%;
        width: width('i/right-arrow-icon.svg'); height: height('i/right-arrow-icon.svg');
        position: absolute;
        right: 27px; top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  div.dropdown--catalogue-link_inline {
    margin-top: 25px;

    a {
      text-transform: none;
    }

    a:hover {
      color: $white;
    }
  }
}


// Footer menu

.footer--menu {
  @extend .menu;

  li + li {
    margin-top: .25em;
  }

  a {
    transition: opacity .25s ease;
  }

  a:hover {
    opacity: .7;
  }

  @media $mobile {
    font-size: 1.8rem;
  }
}

.footer--menu + .footer--menu {
  margin-top: 2em;
}

.footer--menu_payment,
.footer--menu_delivery {
  padding-left: 33px;
}

.footer--menu_payment {
  background: url(/i/payment-icon-blue.svg) no-repeat 0 .1em;
}

.footer--menu_delivery {
  background: url(/i/delivery-icon-blue.svg) no-repeat 0 .25em;
}


// Mobile menu

.mobile-menu {
  display: none;
  position: fixed;
  left: 0;
  z-index: 1001;
  background: #d9dfe7;
  padding: 25px 25px 35px 30px;
  max-width: 320px;
  box-sizing: border-box;
  @extend .menu;

  @media $small-mobile {
    max-width: 100%;
    width: 100%;
  }

  @media $tablet {
    top: $tablet-header-height;
  }

  @media $mobile {
    top: $mobile-header-height;
    height: 'calc(100% - %s)' % $mobile-header-height;
    overflow-y: auto;
  }

  .menu-is-opened & {
    display: block;
  }

  ul {
    font-size: 2rem;
  }

  li + li {
    margin-top: .75em;
  }

  .has-dropdown {
    > a {
      display: block;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 8px; height: 8px;
        box-sizing: border-box;
        border-top: 3px solid $black;
        border-right: 3px solid $black;
        border-radius: 1px;
        transform: rotate(135deg) translate(-50%, 50%);
        position: absolute;
        right: 5px; top: 60%;
        transition: all .25s ease;
      }
    }

    .toggler {
      display: block;
      width: 30px; height: 30px;
      position: absolute;
      right: -6px; top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }
  }

  > ul > .has-dropdown {
    > ul > li {
      padding-bottom: .75em;
    }

    > ul > li + li {
      padding-top: .65em;
      margin-top: 0;
      border-top: 2px solid #d9dfe7;
    }
  }

  .dropdown {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;
    background: $white;
  }

  .dropdown .dropdown {
    margin-top: 0 !important;
  }

  .is-hovered {
    > a:after {
      transform: rotate(-45deg) translate(50%, -50%);
    }

    > .dropdown {
      max-height: none;
      opacity: 1;
      margin-left: -30px;
      margin-right: -25px;
      margin-top: 1em;
      padding: 1em (-@margin-right) 1em (-@margin-left);
    }

    > .all-factories-menu-mobile {
      padding-top: 85px;

      .lists-section {
        &--group {
          margin-top: 20px;
        }
      }
    }
  }

  .dropdown--catalogue-link {
    margin-top: .75em;
    padding-top: 1.5em;
    border-top: 2px solid #d9dfe7;

    a {
      @extend .btn, .blue-btn, .rounded-btn;
      height: 55px;
      line-height: @height;
      font-size: 1.4rem;
      text-transform: uppercase;
      padding: 0 55px 0 30px;
      position: relative;
      box-shadow: 0 4px 20px rgba(#000, .25);
      text-align: left;

      &:after {
        content: '';
        display: block;
        background: url(/i/right-arrow-icon.svg) no-repeat 50%;
        width: width('i/right-arrow-icon.svg'); height: height('i/right-arrow-icon.svg');
        position: absolute;
        right: 25px; top: 50%;
        transform: translateY(-50%);
      }

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.1;
        margin-top: -5px;
        color: $white;
      }
    }
  }

  .header--favorites {
    position: absolute;
    right: 20px; top: 25px;

    .auth-page & {
      top: 55px;
    }
  }

  .auth-page & {
    top: 0;
    padding-top: 55px;
    height: 100%;
  }
}

.mobile-menu--auth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.8rem;
}

.mobile-menu--profile-link {
  padding-left: 48px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -12px;
  background: url(/i/profile-icon-black.svg) no-repeat 0 50%;
}

.mobile-menu--logout-link {
  margin-left: 25px;
}

.mobile-menu--footer {
  margin-top: 80px;
}

.mobile-menu--worktime {
  font-size: 1.8rem;

  p {
    display: inline;
  }
}

.mobile-menu--clients {
  margin-top: 15px;

  ul {
    font-size: 1.8rem;
  }

  .has-dropdown {
    > a, > span {
      display: block;
      padding-right: 22px;
      position: relative;
    }
  }

  .dropdown {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;
  }

  .is-hovered {
    .dropdown {
      opacity: 1;
      padding-top: .75em;
      margin-bottom: -35px;
    }
  }
}

.mobile-menu--colors {
  @extend .colors-menu-prototype;

  .is-hovered & .dropdown {
    padding-right: 20px;
  }
}


// Pager

.pager {
  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
  }

  li + li {
    margin-left: 20px;

    @media $small-mobile {
      margin-left: 10px;
    }

    @media $handheld {
      margin-bottom: 10px;
    }
  }

  a {
    display: block;
    font-size: 1.8rem;
    text-decoration: none;
    transition: all .25s ease;
    width: 47px; height: @width;
    line-height: @height;
    text-align: center;
    border-radius: 50%;
    background-color: $blue;
    color: $white;
    box-shadow: 0 4px 20px rgba(#000, .25);

    @media $small-mobile {
      width: 37px; height: @width;
      line-height: @height;
    }
  }

  li.active a {
    color: #000;
  }

  li:not(.active) a {
    &:hover {
      background-color: $dark-blue;
    }
  }

  .previous a,
  .next a {
    width: auto; height: auto;
    background: none !important;
    color: $blue;
    box-shadow: none;
    border-radius: 0;
    line-height: 1.2;
    border-bottom: 1px solid transparent;
    transition: all .25s ease;

    &:hover {
      border-bottom-color: $blue;
    }
  }

  @media $small-mobile {
    .previous,
    .next {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .previous a {
    /* background-image: url(/i/left-arrow-icon.svg);
    background-repeat: no-repeat;
    background-position: 50%; */
    &:before {
      content: 'Предыдущая';
    }
  }

  .next a {
    /* background-image: url(/i/right-arrow-icon.svg);
    background-repeat: no-repeat;
    background-position: 50%; */
    &:before {
      content: 'Следующая';
    }
  }
}

.ias-trigger {
  margin-top: 1em;
  margin-bottom: 2em;
}

.ias-trigger a {
  display: inline-block;
  padding: 0.75em 2.5em;
  background: $blue;
  color: $white;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: darken($blue, 10);
  }
}

.factory-search-mobile {
  padding-bottom: 10px !important;
  min-width: 250px;
  position: absolute;
  overflow: unset !important;
  max-height: 60px;

  .lists-section {
    margin-bottom: 0;

    &--label-box {
      margin-bottom: 10px;

      .catalog-filter-item{
        width: 90%;
      }

      .filter {
        &--links-group {
          overflow: scroll !important;
          max-height: 600px;
        }
      }
    }

    .radio {
     &:before {
       top: 23%;
     }
    }
  }

  .is-hidden {
    display: none;
  }
}

.all-factories-menu-mobile {
  .lists-section {
    &--column:first-child {
      .lists-section--group:first-child {
        .lists-section--letter {
          padding: 0 14px;
        }
      }
    }
    &--group {
      margin-top: 0 !important;
    }

    &--letter {
      margin-bottom: 0;
      display: inline-block;
      position: absolute;
      margin-top: -10px;
    }

    &--letter-wrap {
      display: flex;
      justify-content: flex-end;
      height: 20px;
      position: relative;
    }

    &--column {
      margin-top: 0 !important;
    }
  }

  li + li {
    margin-top: 0.35em;
  }
}