
/*   -=========== Bookmarks ===========-   */

.bookmarks-page--caption {
  text-align: center;
  position: relative;
  margin-bottom: 35px;
  color: #000;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 60%;
    transform: translateY(-50%);
  }

  .h1 {
    display: inline-block;
    background: $white;
    padding: 0 15px;
    position: relative;
  }
}

.bookmarks-page--caption_two-lines {
  .h1 {
    line-height: 1;
  }

  &:before {
    top: 1.35em;
  }
}

.bookmarks-page--controls {
  /* margin-top: -50px; */
  margin-bottom: 45px;
  text-align: right;
  position: relative;

  @media $small-mobile {
    /* margin-top: -40px; */
    text-align: center;
  }
}

.bookmarkd-page--form-link {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 40px;
  height: 44px;
  padding: 0 38px 0 20px;
  font-size: 1.5rem;

  @media $not-small-mobile {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  @media $small-mobile {
    margin-bottom: 20px;
  }

  &:after {
    content: '';
    display: block;
    width: 6px; height: 6px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    transform: rotate(45deg) translateX(-50%);
    margin-top: -1px;
    position: absolute;
    top: 50%; right: 18px;
  }
}

.bookmarks-page--clear-button {
  display: inline-block;
  font-size: 1.8rem;
  padding-left: 45px;
  position: relative;

  .icon {
    @extend .btn;
    width: 30px; height: @width;
    border-radius: 50%;
    background: #d9dfe7;
    position: absolute;
    left: 0; top: -4px;
    transform: rotate(-45deg);
    transition: background-color .25s ease;

    &:before, &:after {
      content: '';
      display: block;
      background: $white;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 15px; height: 1px;
    }

    &:after {
      width: 1px; height: 15px;
    }
  }

  &:hover .icon {
    background: lighten($red, 10);
  }
}

top-bordered-product() {
  padding-top: 45px;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -46px;
    max-width: 1328px;
  }
}

.bookmarks-vitrine {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media $small-mobile {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .product {
    margin-bottom: 35px;

    @media $desktop {
      flex-basis: calc(20% - 40px);
      margin-right: 50px;

      &:nth-child(5n + 5) {
        margin-right: 0;
      }

      &:nth-child(5) ~ .product {
        top-bordered-product();
      }
    }

    @media $tablet {
      flex-basis: calc(33.33% - 20px);
      margin-right: 30px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:nth-child(3) ~ .product {
        top-bordered-product();
      }
    }

    @media $small-mobile {
      flex-basis: 100%;

      + .product {
        top-bordered-product();
      }
    }
  }

  .product--image {
    position: relative;
    margin-bottom: 15px;

    a {
      display: block;
      padding-bottom: 100%;
      position: relative;

      img {
        width: 100%; height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0; top: 0;
      }
    }
  }

  .product--remove {
    @extend .btn;
    width: 30px; height: @width;
    border-radius: 50%;
    background: #d9dfe7;
    position: absolute;
    top: -13px; right: -13px;
    transform: rotate(-45deg);
    transition: background-color .25s ease;

    &:before, &:after {
      content: '';
      display: block;
      background: $white;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 15px; height: 1px;
    }

    &:after {
      width: 1px; height: 15px;
    }

    &:hover {
      background: lighten($red, 10);
    }
  }

  .product--controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 20px;
    margin-bottom: 5px;
  }

  .product--check {
    display: inline-block;
    width: 29px; height: @width;
    box-sizing: border-box;
    border: 2px solid $blue;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .25s ease;

    input {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/check.svg) no-repeat 50%;
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.is-checked {
      background: $blue;

      &:after {
        opacity: 1;
      }
    }
  }

  .product--params {
    font-size: 1.3rem;
    margin-bottom: 10px;

    th, td {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    th {
      font-weight: normal;
      text-align: left;
    }

    td {
      color: #000;
      padding-left: 5px;
    }

    @media $mobile {
      display: table;
    }
  }

  .product--comment {
    padding: 7px 12px;
    font-size: 1.2rem;
    margin-bottom: 12px;
  }

  .product--buy-btn {
    @extend .btn, .blue-btn, .rounded-btn, .h35btn;
    box-shadow: 0 4px 20px rgba(#000, .25);
    font-size: 1.5rem;
    padding: 0 20px 0 40px;
    background-image: url(/i/cart-icon.svg);
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    transition: all .25s ease;
  }
}


.bookmarks-page--form-container {
  margin-top: 50px;
  margin-bottom: 75px;

  @media $not-small-handheld {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media $small-mobile {
    margin-bottom: 50px;
  }
}

.bookmarks-page--form-description {
  box-sizing: border-box;
  max-width: 660px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 30px;
  font-size: 2.4rem;
  line-height: 1.35;
  padding-left: 165px;
  background: url(/i/mail-pic.svg) no-repeat 0 0;
  color: #828282;

  span {
    display: block;
    color: #000;
    margin-bottom: 1em;
    padding-top: 1.2em;
  }

  @media $small-handheld {
    margin-top: 50px;
  }

  @media $small-mobile {
    font-size: 1.8rem;
    padding-left: 0;
    background: none;
    margin-left: 0;
  }
}
