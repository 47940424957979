
/*   -=========== Vitrine ===========-   */

.vitrine-paddings {
  @media $desktop {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media $handheld {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.vitrine {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  /* @media $notebook {
    max-width: 1060px;
  } */

  /* @media $landscape-tablet {
    max-width: 790px;
  } */

  @media $small-handheld {
    /* max-width: 520px; */

    .tabs-content & {
      padding-top: 20px;
    }
  }

  /* @media $mobile {
    max-width: 250px;
  } */

  @media $mobile {
    margin-left: 10px;
    margin-right: 10px;
  }

  .product {
    padding-top: 35px;
    padding-bottom: 35px;
    overflow-x: hidden;

    @media $notebook {
      flex-basis: calc(16.66% - 17px);
    }

    @media $landscape-tablet {
      flex-basis: calc(20% - 8px);
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media $small-handheld {
      @media $not-small-mobile {
        flex-basis: calc(100% / 3 - 12px);
        padding-top: 20px;
        padding-bottom: 20px;

        //&:not(:nth-child(4n + 1)) {
        //  margin-left: 16px;
        //}
      }
    }

    @media $small-mobile {
      flex-basis: calc(50% - 5px);
      padding-top: 15px;
      padding-bottom: 10px;

      //&:nth-child(even) {
      //  margin-left: 10px;
      //}
    }
  }

  .product-info-btn{
    position: relative;
    z-index: 2;
    margin: 30px 0 50px 0;
    &:not(.sale-group-info){
      margin: 0!important;
      .product--fav-btn{
        top: 25px;

        &.archive-product {
          top: 10px;
        }
      }
    }
  }

  .sale-info-text{
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.1;
    @media $small-mobile {
      font-size: 1.2rem;
    }
  }
  .old-price-sale{
    font-size: 2.6rem!important;
    @media only screen and (max-width: 1400px) and (min-width: 481px){
      font-size: 2.1rem!important;
    }
  }
  .discount-sale{
    font-size: 1.6rem!important;
    @media only screen and (max-width: 1400px) and (min-width: 481px){
      font-size: 1.4rem!important;
    }
  }

  .sale-group{
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .product--name {
    font-size: 1.4rem;
    line-height: 1.1;
    text-align: center;
    height: 1.4rem * @line-height * 2 + 1;
    overflow: hidden;
    text-overflow: ellipsis;

    .product--size-description {
      line-height: 1.1;
      text-decoration: overline;
      text-decoration-color: darkgray;
      margin-top: 6px;
      display: block;
    }

    a {
      text-decoration: none;
    }

    @media $small-mobile {
      min-height: 5.3rem;
      max-height: @min-height;
      margin-bottom: .5em;

      .product--name-span {
        min-height: @font-size * @line-height * 2;
        max-height: @min-height;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .product--link {
    display block;
  }

  .product--image {
    padding-bottom: 125%;
    position: relative;
    overflow: hidden;

    img {
      /* width: 100%; height: 100%; */
      object-fit: cover;
      aspect-ratio: 5/6;
      margin: auto;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @media $small-mobile {
        width: 100%; height: 100%;
        object-fit: cover;
      }
    }
  }

  .product--color {
    width: 35px;
    height: 20px;
    border-radius: 25px;
    z-index: 5;
    position: relative;
    top: 5px;
    left: 5px;
  }

  .product--icons {
    @extend .product-icons-prototype;
    position: absolute;
    left: 14px; top: 50%;
    transform: translateY(-50%);

    @media $small-mobile {
      top: 40px;
      transform: none;
    }
  }

  .product--params-container {
    box-sizing: border-box;
    width: 100%;
    padding: 30px 10px 5px;
    position: absolute;
    left: 0; top: 0;
    z-index: 5;
    background: rgba($white, .6);
    text-align: center;
    transition: opacity .25s ease;

    html:not(.is-touch-device) & {
      opacity: 0;
    }

    @media $small-mobile {
      display: none;
    }
  }

  .product--size {
    font-size: 1.4rem;
    color: #000;
  }

  .product--params {
    font-size: 1.2rem;
    color: $dark-gray;
  }

  .product--size + .product--params {
    margin-top: 3px;
  }

  .product--zoom-link {
    width: 47px; height: 40px;
    background: rgba($white, .6) url(/i/zoom-icon.svg) no-repeat 40% 65%;
    text-decoration: none;
    position: absolute;
    left: 0; bottom: 0;
    transition: all .25s ease;
    z-index 7;

    html:not(.is-touch-device) & {
      opacity: 0;
    }

    .counter {
      display: block;
      width: 19px; height: @width;
      line-height: @height;
      text-align: center;
      background: $blue;
      color: $white;
      font-size: 1.1rem;
      border-radius: 50%;
      position: absolute;
      right: 10px; top: 6px;
    }

    @media $small-mobile {
      display: none;
    }
  }

  .product--buy-btn {
    @extend .btn, .blue-btn, .rounded-btn, .h35btn;
    box-shadow: 0 4px 20px rgba(#000, .25);
    font-size: 1.5rem;
    padding: 0 20px 0 40px;
    white-space: nowrap;
    background-image: url(/i/cart-icon.svg);
    background-repeat: no-repeat;
    background-position: 15px 50%;
    background-size: 20px auto;
    position: absolute;
    bottom: 20px; left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 7;
    transition: all .25s ease;

    @media $small-handheld {
      @media $not-small-mobile {
        bottom: 30px;
      }
    }

    html:not(.is-touch-device) & {
      opacity: 0;
    }
  }

  .product--footer {
    line-height: 52px;
    position: relative;
  }

  .product--price-group {
    text-align: center;
    margin: 13px 0 5px 0;
    line-height: normal;
  }

  .product--old-price,
  .product--price,
  .old-price-sale {
    display: inline-block;
  }

  .product--price {
    font-size: 1.8rem;
    color: #000;
    line-height: normal;

    .call {
      display: inline-block;
      line-height: 1;
      text-align: left;
      margin-top: 5px;
    }
  }

  .product--old-price {
    font-size: 1.3rem;
    color: #828282;
    text-decoration: line-through;
  }

  .product--old-price + .product--price,
  .old-price-sale + .product--price {
    margin-left: 15px;
  }

  .sale-amount {
      font-weight: 900;
  }

  .product--discount {
    width: 36px; height: @width;
    line-height: @height;
    text-align: center;
    border-radius: 50%;
    background: $blue;
    color: $white;
    text-align: center;
    font-size: 1.4rem;
    position: absolute;
    left: 12px; top: 50%;
    transform: translateY(-50%);
  }

  .product--fav-btn {
    @extend .btn;
    width: 28px; height: @width;
    background: url(/i/heart-icon-contour.svg) no-repeat 50% / 18px auto;
    font-size: 0;
    position: absolute;
    right: 10px; top: 50%;
    transform: translateY(-50%);
    transition: opacity .25s ease;

    html:not(.is-touch-device) & {
      opacity: 0;
    }

    &.active {
      background-image: url(/i/heart-icon.svg);
    }
  }

  .product:hover {
    .product--params-container,
    .product--zoom-link,
    .product--buy-btn,
    .product--fav-btn {
      opacity: 1;
    }

    @media $small-mobile {
      .product--params-container {
        opacity: 0 !important;
      }
    }
  }
}

.vitrine:not(.vitrine_catalogue) {
  .product {
    @media $notebook {
      flex-basis: calc(20% - 16px);

      &:not(:nth-child(5n + 5)) {
        margin-right: 20px;
      }

      margin-right: 20px;
      &:nth-child(5n + 5) {
        margin-right: inherit;
      }
    }

    @media $not-notebook {
      flex-basis: calc(16.66% - 17px);

      &:not(:nth-child(6n + 6)) {
        margin-right: 20px;
      }

      margin-right: 20px;
      &:nth-child(6n + 6) {
        margin-right: inherit;
      }
    }

    @media $landscape-tablet {

      &:not(:nth-child(4n + 4)) {
        margin-right: 10px;
      }

      margin-right: 10px;
      &:nth-child(5n + 5) {
        margin-right: inherit;
      }
    }

    @media $small-mobile {
      flex-basis: calc(50% - 5px);
      padding-top: 15px;
      padding-bottom: 10px;

      &:nth-child(even) {
        margin-left: 10px;
      }
    }
  }
}

.vitrine_catalogue {
  .product {
    @media $not-notebook {
      flex-basis: calc(20% - 16px);
      &:not(:nth-child(5n + 5)) {
        margin-right: 20px;
      }
    }

    @media $not-small-mobile {
      @media $notebook-and-less {
        flex-basis: calc(100% / 3 - 8px);
        margin-left: 0 !important;

        &:not(:nth-child(3n + 3)) {
          margin-right: 10px;
        }
      }

      // @media $landscape-tablet {
      //   flex-basis: calc(100% / 3 - 8px);

      //   &:not(:nth-child(4n + 4)) {
      //     margin-right: 0;
      //   }

      //   &:not(:nth-child(5n + 5)) {
      //     margin-right: 10px;
      //   }
      // }
    }

    @media $small-handheld {
      @media $not-small-mobile {
        flex-basis: calc(100% / 2 - 8px);

        &:not(:nth-child(3n + 3)) {
          margin-right: 0;
        }

        &:not(:nth-child(2n + 2)) {
          margin-right: 10px;
        }
      }
    }

    @media $small-mobile {
      flex-basis: calc(50% - 5px);
      padding-top: 15px;
      padding-bottom: 10px;

      &:nth-child(even) {
        margin-left: 10px;
      }
    }
  }
}

.vitrine_three-lines {
  @media $not-notebook {
    .product:nth-child(18) ~ .product {
      display: none;
    }
  }

  @media $notebook {
    .product:nth-child(18) ~ .product {
      display: none;
    }
  }

  @media $landscape-tablet {
    .product:nth-child(12) ~ .product {
      display: none;
    }
  }

  @media $small-handheld {
    @media $not-small-mobile {
      .product:nth-child(6) ~ .product {
        display: none;
      }
    }
  }

  @media $small-mobile {
    .product:nth-child(4) ~ .product {
      display: none;
    }
  }
}

.vitrine_four-lines {
  @media $not-notebook {
    .product:nth-child(24) ~ .product {
      display: none;
    }
  }

  @media $notebook {
    .product:nth-child(24) ~ .product {
      display: none;
    }
  }

  @media $landscape-tablet {
    .product:nth-child(24) ~ .product {
      display: none;
    }
  }

  @media $small-handheld {
    @media $not-small-mobile {
      .product:nth-child(8) ~ .product {
        display: none;
      }
    }
  }

  @media $small-mobile {
    .product:nth-child(8) ~ .product {
      display: none;
    }
  }
}
