
/*   -=========== Search ===========-   */

.search-page {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 50px;

  .pager {
    text-align: center;
  }
}

.empty_query_label {
  margin-top: 70px;
  margin-bottom: 70px;
}
