
/*   -=========== Forms ===========-   */

// Base

input,
textarea {
  font: inherit;
}

.form {
  span.required {
    color: $warning-color;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: .33em 20px;
  line-height: 1.25em;
  color: lighten($text-color, 5);
  border: 1px solid #bdbdbd;
  transition: all .25s ease;
  font-size: 2rem;

  @media $small-handheld {
    font-size: 1.4rem;
    padding-top: .4em;
    padding-bottom: .4em;
  }

  &:focus {
    color: $text-color;
    border-color: $black;
  }

  .form-field.error > & {
    border-color: $warning-color;
    color: $warning-color;
  }
}

.checkbox,
.radio {
  padding-left: 33px;
  position: relative;
  font-size: 1.2rem;
  padding-top: .15em;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:before {
    content: '';
    display: block;
    width: 18px; height: @width;
    box-sizing: border-box;
    position: absolute;
    left: 0; top: 0;
    border: 1px solid #c4c4c4;
    background: $white;
    border-radius: 50%;
  }

  &.is-checked:before {
    background: #000;
    border-color: #000;
  }

  input {
    display: none;
  }
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  padding: 0 32px;
  position: relative;

  .inp {
    vertical-align: middle;
    border: none;
    width: 40px;
    padding: 0;
    text-align: center;
    font-size: 1.8rem;

    @media $small-mobile {
      display: block;
      font-size: 20px;
    }
  }

  .spinner-up,
  .spinner-down {
    @extend .btn;
    box-sizing: border-box;
    vertical-align: middle;
    width: 30px; height: @width;
    line-height: 17px;
    text-align: center;
    border: 2px solid #bdbdbd;
    border-radius: 50%;
    background: transparent;
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .spinner-down {
    left: 0;
  }

  .spinner-up {
    right: 0;
  }

  .spinner-unit {
    position: absolute;
    left: 50%; top: 100%;
    transform: translateX(-50%);
    margin-top: 3px;
    font-size: 1.4rem;

    @media $small-mobile {
      position: static;
      transform: none;
      font-size: 16px;
      line-height: 16px;
      margin: 0;
    }
  }
}


.form-row {
  clearfix();

  + .form-row {
    .one-column-form & {
      margin-top: 2.25em;
    }

    .two-columns-form & {
      margin-top: 1.5em;
    }
  }
}

.form-label {
  .one-column-form & {
    margin-bottom: .15em;
  }

  .two-columns-form & {
    float: left;
  }
}

.form-field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1.1;
    color: $warning-color;
    position: absolute
    left: 0; top: 100%;
    width: 100%;
    z-index: 10;

    @media $not-small-mobile {
    }
  }

  &.error {
    .errorMessage {
      display: block;
    }
  }
}

.form-hint {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #000;

  span.required {
    font-size: 1.8rem;
    color: #000;
  }

  .one-column-form & {
    margin-top: 2em;
  }

  .two-columns-form & {
    margin-top: 1.25em;
  }
}

.form-submit {
  .one-column-form & {
    text-align: center;

    @media $small-mobile {
    }
  }

  .two-columns-form & {
    margin-top: 1.25em;
  }

  .submit-button {
    @extend .btn, .blue-btn, .h40btn, .rounded-btn;
    padding: 0 50px 0 25px;
    font-size: 1.5rem;
    box-shadow: 0 4px 20px rgba(#000, .25);
    position: relative;

    &:after {
      content: '';
      display: block;
      background: url(/i/right-arrow-icon.svg) no-repeat 50%;
      width: width('i/right-arrow-icon.svg'); height: height('i/right-arrow-icon.svg');
      position: absolute;
      right: 23px; top: 50%;
      transform: translateY(-50%);
    }
  }
}


.callback-form {
  max-width: 415px;
}


.order-form {
  .form-row + .form-row,
  .form-row + .fieldset--caption,
  .fieldset--caption + .form-row {
    margin-top: 2em;

    @media $small-mobile {
      margin-top: 1.25em;
    }
  }
  .form-row + .form-row {
    margin-top: 15px;
  }
  .form--fields-main {
    .form-row + .form-row {
      margin-top: 13px;
    }
  }
  .form-column {
    width: calc(100% / 3 - 50px)
    @media $small-mobile {
      width: 100%;
      padding: 30px;
      background-color: #EBF5FF;
      border: 1px solid #97CDFA;
      border-radius: 10px;
    }

    
  }

  .radio-input + .radio-input {
    margin-top: 0.8em;
  }

  .fieldset--caption_radio-caption {
    padding-left: 20px;
  }

  @media $small-handheld {
    .fieldset--caption {
      font-size: 1.6rem;
    }
  }

  .form-submit {
    @media $not-small-handheld {
      text-align: center;
    }

    @media $small-handheld {
      text-align: center;
    }
  }

  .submit-button {
    font-size: 2.2rem;
    padding: 0 25px;
    height: 49px;
    line-height: 42px;
    width: 100%;
    border-radius: 10px;
    background-color: #47A0F3;
    margin-top: 16px;
    @media $mobile {
      margin: 0;
    }

    &:after {
      display: none;
    }
  }

  .radio {
    padding-top: 0;
    padding-left: 20px;
    font-size: 1.8rem;

    &:before {
      width: 12px; height: @width;
      background: #d9dfe7;
      border: none;
      top: 4px;
    }

    &.is-checked:before {
      background: $blue;
    }
  }
}

.order-form--price {
  font-size: 2.4rem;
  white-space: nowrap;
  color: #000;

  @media $small-handheld {
    display: block;
    font-size: 1.8rem;
    text-align: center;
  }
}

.order-form--price-value {
  font-size: 3.6rem;

  @media $not-small-handheld {
    margin-left: 10px;
  }

  @media $small-handheld {
    display: block;
    font-size: 2.4rem;
    text-align: center;
    margin-top: 5px;
  }
}


.bookmarks-form {
  flex-shrink: 0;

  @media $not-small-mobile {
    width: 385px;
  }

  @media $small-handheld {
    margin-left: auto;
    margin-right: auto;
  }

  .form-row + .form-row {
    margin-top: 2em;
  }

  .submit-button {
    box-sizing: border-box;
    border-radius: 45px;
    width: 100%;
    padding: 12px 10px 15px 85px;
    height: auto;
    font-size: 2.2rem;
    line-height: 1.1;

    @media $small-mobile {
      font-size: 1.8rem;
    }

    > span {
      display: inline-block;
      text-align: left;
    }

    &:before {
      content: '';
      display: block;
      width: 85px; height: 100%;
      background: url(/i/check-big.svg) no-repeat 50%;
      position: absolute;
      lefT: 0; top: 0;
    }

    &:after {
      display: none;
    }
  }
}


.fast-order-form {
  .form-submit {
    @media $not-small-mobile {
      text-align: left;
    }

    .submit-button {
      height: 49px;
      line-height: 45px;
      padding: 0 25px 0 55px;
      font-size: 2.2rem;

      @media $small-mobile {
        font-size: 1.7rem;
      }

      &:after {
        display: none;
      }

      &:before {
        content: ''
        display: block;
        width: 58px; height: 100%;
        position: absolute;
        left: 0; top: 0;
        background: url(/i/cart-icon.svg) no-repeat 50%;
      }
    }
  }
}


.found-cheaper-form {
  .submit-button {
    font-size: 2.2rem;
    padding-left: 30px;
    padding-right: 30px;
    height: 49px;
    line-height: 45px;

    &:after {
      display: none;
    }
  }
}


.settings-form {
  .form-hint {
    color: #828282;
    font-size: 1.3rem;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .form-label {
    margin-bottom: 1em;
  }

  .radio-item + .radio-item {
    margin-top: .5em;
  }

  .radio {
    font-size: 2rem;
    padding-top: 0;

    &:before {
      width: 12px; height: @width;
      border: none;
      background: #d9dfe7;
      margin-top: .45em;
    }

    &.is-checked:before {
      background: $blue;
    }
  }
}


.auth-form {
  max-width: 380px;

  .form-row + .form-row,
  .form-submit {
    margin-top: 1.5em;
  }

  .errorSummary {
    margin-bottom: 1.5em;
    font-size: 1.2rem;
  }
}

.auth-page--form-footer {
  max-width: 380px;
  text-align: center;
  margin-top: 1.5em;
  font-size: 1.6rem;
}


.feedback-form {
  width: 320px;
  max-width: 100%;
}
