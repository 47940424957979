
/*   -=========== Homepage ===========-   */

.main-rotator-container {
  position: relative;

  @media $desktop {
    padding-bottom: 31.5%;
  }

  @media $landscape-tablet {
    padding-bottom: 36.5%;
  }

  @media $small-handheld {
    @media $not-small-mobile {
      padding-bottom: 52.5%;
    }
  }
}

.main-rotator {
  @media $not-mobile {
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  @media $mobile {
    height: 300px;
  }

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-dots {
    position: absolute;
    left: 50%; bottom: 30px;
    transform: translateX(-50%);
    z-index: 1000;

    li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
    }

    button {
      display: block;
      width: 12px; height: 12px;
      border-radius: 50%;
      background: rgba($white, .5);
      font-size: 0;
    }

    .slick-active button {
      background: $white;
    }

    li + li {
      margin-left: 10px;
    }
  }

  .slick-arrow {
    @extend .btn;
    width: 22px; height: 70px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    background: none;
    font-size: 0;
    opacity: .5;

    @media $small-mobile {
      display: none !important;
    }

    &:hover {
      opacity: 1;
    }

    &:active {
      margin-top: 1px;
    }

    &:before, &:after {
      content: '';
      display: block;
      width: 3px; height: 40px;
      background: $white;
      position: absolute;
    }

    &:before {
      bottom: 50%;
    }

    &:after {
      top: 50%;
    }
  }

  .slick-prev {
    left: 20px;

    &:before, &:after {
      left: 0;
    }

    &:before {
      transform-origin: left bottom;
      transform: rotate(30deg);
    }

    &:after {
      transform-origin: left top;
      transform: rotate(-30deg);
    }
  }

  .slick-next {
    right: 20px;

    &:before, &:after {
      right: 0;
    }

    &:before {
      transform-origin: right bottom;
      transform: rotate(-30deg);
    }

    &:after {
      transform-origin: right top;
      transform: rotate(30deg);
    }
  }

  .item {
    display: flex !important;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    color: $white;
    position: relative;

    @media $not-small-handheld {
      align-items: center;
    }

    @media $small-handheld {
      align-items: flex-start;
      padding-top: 25px;
    }

    .wrapper {
      width: 100%;
      top: 3%;
      position: relative;

      @media $notebook {
        padding-left: 2vw;
      }

      @media $tablet {
        padding-left: 5vw;
      }
    }

    > :not(.item--overlay) {
      pointer-events: none;

      a {
        pointer-events: auto;
      }
    }
  }

  .item--overlay {
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  .item--caption {
    line-height: 1;

    @media $not-small-mobile {
      max-width: 50%;
    }

    @media $not-notebook {
      font-size: 6.5rem;
    }

    @media $notebook {
      font-size: 5.5rem;
    }

    @media $landscape-tablet {
      font-size: 4.5rem;
    }

    @media $small-handheld {
      font-size: 3rem;
    }
  }

  .item--text {
    margin-top: 10px;
    margin-bottom: 35px;

    @media $not-notebook {
      font-size: 2rem;
    }

    @media $notebook {
      font-size: 1.8rem;
    }

    @media $handheld {
      font-size: 1.5rem;
    }
  }

  .item--link {
    @extend .btn, .h40btn, .blue-btn, .rounded-btn;
    font-size: 1.5rem;
    padding: 0 50px 0 40px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: width('i/right-arrow-icon.svg'); height: height('i/right-arrow-icon.svg');
      background: url(/i/right-arrow-icon.svg) no-repeat 50%;
      position: absolute;
      right: 23px; top: 50%;
      transform: translateY(-50%);
    }
  }
}

.side-rotator {
  @media $not-small-mobile {
    position: absolute;
  }

  @media $not-small-handheld {
    top: 55%;
    transform: translateY(-50%);
  }

  @media $not-notebook {
    right: 15vw;
  }

  @media $notebook {
    right: 7vw;
  }

  @media $landscape-tablet {
    right: 7vw;
  }

  @media $small-handheld {
    right: 0; top: 0;
  }

  .item {
    box-sizing: border-box;
    background: $blue;
    color: $white;
    padding: 35px 45px 30px;
    text-decoration: none;

    @media $not-small-mobile {
      margin-bottom: (47px / 2);
    }

    @media $desktop {
      max-width: 480px;
    }

    @media $landscape-tablet {
      max-width: 400px;
    }

    @media $small-handheld {
      max-width: 100%;
    }
  }

  .item--caption {
    margin-bottom: 25px;

    @media $desktop {
      font-size: 2.4rem;
    }

    @media $tablet {
      font-size: 2rem;
    }

    @media $small-mobile {
      font-size: 1.8rem;
    }
  }

  .item--text {
    @media $desktop {
      font-size: 1.8rem;
    }

    @media $handheld {
      font-size: 1.5rem;
    }
  }

  .item--link {
    width: 47px; height: @width;
    border-radius: 50%;
    background: $dark-gray url(/i/right-arrow-icon.svg) no-repeat 50%;
    transition: background-color .25s ease;

    @media $not-small-mobile {
      position: absolute;
      right: 38px; bottom: 0;
    }

    @media $small-mobile {
      margin-top: 30px;
    }
  }

  .item:hover .item--link {
    background-color: #000;
  }

  p + p,
  li + li {
    margin-top: 1em;
  }

  li {
    list-style: none;
    padding-left: 34px;
    background: url(/i/mark-icon.svg) no-repeat 0 .1em;

    @media $tablet {
      background-position: 0 .05em;
    }
  }
}


.about-company {
  background: #edf3f9 url(/i/about-bg.jpg) no-repeat 10% 100%;

  @media $not-small-handheld {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50%;
  }

  @media $notebook {
    background-position: 0 100%;
  }

  @media $landscape-tablet {
    background-position: -10% 100%;
    background-size: 65% auto;
  }

  @media $small-handheld {
    padding-top: 35px;
    padding-bottom: 30px;
    padding-left: 42%;
    background-size: 76% auto;
    background-position: -71% 100%;
  }

  @media $mobile {
    background: #edf3f9;
    padding: 20px 30px 30px 55px;
  }
}

.about-company--content {
  @media $not-small-handheld {
    max-width: 720px;
  }

  @media $small-handheld {
    max-width: 430px;
  }
}

.about-company--caption {
  line-height: 1;
  margin-bottom: 25px;

  @media $not-notebook {
    font-size: 3.9rem;
  }

  @media $notebook {
    font-size: 3.4rem;
  }

  @media $landscape-tablet {
    font-size: 3rem;
  }

  @media $portrait-tablet {
    font-size: 2.5rem;
  }

  @media $mobile {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

.about-company--subcaption {
  font-size: 2.2rem;

  @media $not-notebook {
    font-size: 2.2rem;
  }

  @media $notebook {
    font-size: 2.1rem;
  }

  @media $landscape-tablet {
    font-size: 2rem;
  }

  @media $portrait-tablet {
    font-size: 1.8rem;
  }

  @media $mobile {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.about-company--features {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media $not-notebook {
    max-height: 450px;
  }

  @media $notebook {
    max-height: 360px;
    margin-bottom: 40px;
  }

  @media $landscape-tablet {
    max-height: 280px;
    margin-bottom: 80px;
    padding-right: 20px;
  }

  @media $portrait-tablet {
    max-height: 280px;
  }

  .item {
    box-sizing: border-box;
    list-style: none;
    margin-bottom: 1em;
    font-size: 2.6rem;
    padding-left: 28px;
    background: url(/i/mark-icon-dark.svg) no-repeat 0 .35em;

    @media $not-mobile {
      width: calc(50% - 5px);
    }

    @media $not-notebook {
      font-size: 2.6rem;
    }

    @media $notebook {
      font-size: 2rem;
    }

    @media $handheld {
      font-size: 1.6rem;
    }
  }

  @media $not-mobile {
    .item:nth-child(3) ~ .item {
      margin-left: 10px;
    }
  }
}


.company-features {
  @media $desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media $landscape-tablet {
    padding-top: 25px;
    padding-bottom: 10px;
  }

  @media $small-handheld {
    padding-top: 30px;
    padding-bottom: 35px;
  }
}

.company-features--caption {
  @extend .h1;
  overflow: hidden;
  text-align: center;
  position: relative;

  @media $not-mobile {
    margin-bottom: 60px;
  }

  @media $mobile {
    margin-bottom: 25px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 55%;
    z-index: -1;
  }

  span {
    background: $white;
    padding-left: 40px;
    padding-right: 40px;

    @media $mobile {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.company-features--list {
  display: flex;

  @media $small-handheld {
    flex-direction: column;
  }

  @media $small-mobile {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }

  .item {
    list-style: none;
    flex: 1;

    @media $not-small-handheld {
      text-align: center;
    }

    @media $small-handheld {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .item + .item {
    @media $not-small-handheld {
      margin-left: 20px;
    }

    @media $small-handheld {
      margin-top: 45px;
    }
  }

  .item--pic {
    @media $desktop {
      margin-bottom: 45px;
    }

    @media $landscape-tablet {
      margin-bottom: 30px;
    }

    @media $small-handheld {
      margin-right: 30px;
      flex-shrink: 0;
      width: 145px;
    }

    @media $mobile {
      width: 80px;
      margin-right: 10px;
    }
  }

  .item--text {
    @media $not-notebook {
      font-size: 3rem;
    }

    @media $notebook {
      font-size: 1.8rem;
    }

    @media $landscape-tablet {
      font-size: 1.4rem;
    }

    @media $portrait-tablet {
      font-size: 2.2rem;
    }

    @media $mobile {
      font-size: 1.4rem;
    }
  }
}


.popular-categories {
  background: #f1f2f6;

  @media $desktop {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  @media $landscape-tablet {
    padding-top: 40px;
    padding-bottom: 45px;

    .wrapper {
      margin-left: 8vw;
      margin-right: 8vw;
    }
  }

  @media $small-handheld {
    padding-top: 25px;
    padding-bottom: 45px;

    .wrapper {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.popular-categories--caption {
  @extend .h1;
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;

  @media $desktop {
    margin-bottom: 70px;
  }

  @media $landscape-tablet {
    margin-bottom: 50px;
  }

  @media $small-handheld {
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 55%;
    z-index: -1;
  }

  span {
    background: #f1f2f6;
    padding-left: 40px;
    padding-right: 40px;

    @media $mobile {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.popular-categories--list {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @media $not-small-mobile {
    padding-left: calc(29% + 20px);

    @media $small-handheld {
      height: 500px;
    }
  }

  .item {
    position: relative;
    overflow: hidden;
    background: $black;
  }

  @media $not-small-mobile {
    .item_2,
    .item_3,
    .item_5 {
      margin-right: 20px;
    }

    .item_5,
    .item_6 {
      margin-top: 20px;
    }
  }

  @media $small-mobile {
    .item:nth-child(odd) {
      margin-right: 14px;
    }

    .item:nth-child(2) ~ .item {
      margin-top: 14px;
    }
  }

  @media $not-small-mobile {
    .item_2 {
      flex-basis: calc(28% - 10px);
    }

    .item_3 {
      flex-basis: calc(28% - 10px);
    }

    .item_4 {
      flex-basis: calc(44% - 20px);
    }

    .item_5 {
      flex-basis: calc(40% - 10px);
    }

    .item_6 {
      flex-basis: calc(60% - 10px);
    }

    .item_big {
      position: absolute;
      left: 0; top: 0;
      width: 29%; height: 100%;
    }
  }

  @media $small-mobile {
    .item {
      height: 255px;
    }

    .item_1,
    .item_4,
    .item_5 {
      width: calc(57% - 7px);
    }

    .item_2,
    .item_3,
    .item_6 {
      width: calc(43% - 7px);
    }
  }

  .item--name {
    position: absolute;
    bottom: 30px; left: 8%;
    color: $white;
    line-height: 1.1;

    @media $not-notebook {
      font-size: 5.5rem;
    }

    @media $notebook {
      font-size: 4.5rem;
    }

    @media $tablet {
      font-size: 3.3rem;
    }
  }

  .item--name_smallfont {
    @media $not-notebook {
      font-size: 4rem;
    }

    @media $notebook {
      font-size: 3.5rem;
    }

    @media $tablet {
      font-size: 2.2rem;
    }
  }

  .item--image {
    width: 100%; height: 100%;
    object-fit: cover;
    transition: opacity .25s ease;
  }

  .item:hover .item--image {
    opacity: .7;
  }
}

.popular-categories--list + .popular-categories--list {
  margin-top: 20px;
}

.popular-categories--list_right {
  @media $not-small-mobile {
    padding-left: 0;
    padding-right: calc(29% + 20px);
  }

  @media $not-small-mobile {
    .item_7 {
      margin-right: 20px;
    }

    .item_9 {
      margin-top: 20px;
    }
  }

  @media $not-small-mobile {
    .item_7 {
      flex-basis: calc(42.8% - 10px);
    }

    .item_8 {
      flex-basis: calc(57.1% - 10px);
    }

    .item_9 {
      flex-basis: 100%;
    }

    .item_big {
      position: absolute;
      left: auto; right: 0; top: 0;
      width: 29%; height: 100%;
    }
  }

  @media $small-mobile {
    .item_8,
    .item_9 {
      width: calc(57% - 7px);
    }

    .item_7,
    .item_10 {
      width: calc(43% - 7px);
    }
  }
}


.homepage-vitrine-tabs {
  padding-top: 50px;
  position: relative;
  z-index: 2;

  @media $small-mobile {
    padding-top: 20px;
  }

  .tabs-content {
    padding-bottom: 45px;
  }
}

.homepage-vitrine-tabs--more-link,
.brands-section--more-link {
  @extend .btn, .blue-btn, .h40btn, .rounded-btn;
  box-sizing: border-box;
  width: 166px;
  padding-right: 25px;
  position: relative;
  font-size: 1.5rem;
  box-shadow: 0 4px 20px rgba(#000, .25);

  &:after {
    content: '';
    display: block;
    background: url(/i/right-arrow-icon.svg) no-repeat 50%;
    width: width('i/right-arrow-icon.svg'); height: height('i/right-arrow-icon.svg');
    position: absolute;
    right: 20px; top: 50%;
    transform: translateY(-50%);
  }
}


.brands-section {
  background: url(/i/header-pattern.jpg) 50% 0;

  @media $desktop {
    padding-top: 80px;
    padding-bottom: 85px;
  }

  @media $handheld {
    padding-top: 30px;
    padding-bottom: 35px;
  }
}

.brands-section--caption {
  @extend .h1;
  overflow: hidden;
  text-align: center;
  position: relative;
  z-index: 1;

  @media $desktop {
    margin-bottom: 50px;
  }

  @media $handheld {
    margin-bottom: 30px;
  }

  span {
    display: inline-block;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;

    @media $mobile {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 50vw; height: 1px;
      background: #828282;
      position: absolute;
      top: 65%;
      z-index: -1;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }
}

.brands-section .brands-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media $small-handheld {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .item {
    flex-basis: 20%;
    margin-bottom: 40px;
    text-align: center;
    opacity: .7;
    transition: opacity .25s ease;

    &:hover {
      opacity: 1;
    }

    img {
      max-width: 80%;
    }

    @media $small-handheld {
      flex-basis: 33.33%;

      &:nth-child(6) ~ .item {
        display: none;
      }
    }

    @media $small-mobile {
      flex-basis: 50%;

      &:nth-child(4) ~ .item {
        display: none;
      }
    }
  }
}


.designers-section {
  background: #d9dfe7;
  position: relative;

  @media $not-small-handheld {
    padding-left: 50%;

    &:before {
      content: '';
      display: block;
      width: 50%; height: 100%;
      background: #d9dfe7 url(/i/designers-bg.jpg) no-repeat 50% 0;
      position: absolute;
      left: 0; top: 0;

      @media $tablet {
        background-size: auto 100%;
      }
    }
  }
}

.designers-section_custom {
  display: flex;
  align-items: center;
  min-height: 470px;
  padding-top: 10px;
  padding-bottom: 30px;
  box-sizing: border-box;

  p + p {
    margin-top: 2em;
  }
}

.designers-section--content {
  @media $desktop {
    padding: 50px 12vw 100px 2vw;
  }

  @media $tablet {
    padding: 25px 1vw 40px 2vw;
  }

  @media $mobile {
    padding: 20px 40px 35px 50px;
  }

  .designers-section_custom & {
    max-width: 520px;
  }
}

.designers-section--caption {
  @extend .h1;
  line-height: 1;
  margin-bottom: 40px;

  @media $landscape-tablet {
    font-size: 3.8rem;
  }

  @media $small-handheld {
    @media $not-small-mobile {
      font-size: 2.8rem;
    }
  }

  @media $small-mobile {
    margin-bottom: 15px;
  }
}

.designers-section--features {
  @media $desktop {
    font-size: 1.8rem;
  }

  @media $tablet {
    font-size: 1.4rem;
    max-width: 360px;
  }

  @media $mobile {
    font-size: 1.5rem;
  }

  li + li {
    @media $not-small-mobile {
      margin-top: 1.5em;
    }

    @media $small-mobile {
      margin-top: 1em;
    }
  }

  li {
    list-style: none;
    padding-left: 30px;
    position: relative;
    background: url(/i/mark-icon-dark.svg) no-repeat 0 .2em / 13px auto;
  }
}
