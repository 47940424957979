
/*   -=========== Structure ===========-   */

// Base

body {
  overflow-x: hidden;
  /* display: table; */
  /* width: 100%; */
 @media $mobile {
    padding-top: 94px
 }
  @media $small-mobile {
    &.menu-is-opened {
      overflow: hidden;
      max-height: 100vh;
    }
  }
}

#structure {
  /* display: table-row; */
  /* height: 100%; */
  margin: auto;
  position: relative;
}

.left-aside {
  float: left;
  width: calc(18% - 10px);
  box-sizing: border-box;

  @media $notebook {
    width: 250px;
  }

  @media $not-mobile {
    padding-left: 35px;
  }
}

.main-section {
  position: relative;
}

.left-aside + .main-section {
  float: right;
  width: calc(82% - 10px);
  box-sizing: border-box;

  @media $not-small-mobile {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media $notebook {
    padding-right: 40px;
    width: calc(100% - 260px);
  }
}

.footer {
  position: relative;
  background: #333;
  font-size: 1.6rem;
  line-height: 1.35;

  @media $small-handheld {
    font-size: 1.4rem;
  }

  &, a {
    color: $white;
  }
}


// Pre header

.pre-header {
  display: none;
  background: url(/i/pre-header-bg.jpg) 50%;

  @media $handheld {
    .menu-is-opened & {
      display: none !important;
    }
  }
}

.pre-header--flex-container {
  display: flex;
  align-items: center;
  min-height: 136px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.pre-header--content {
  box-sizing: border-box;
  width: 920px;
  max-width: calc(100vw - 20px);
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
  line-height: 1.35;
  padding-right: 50px;
  position: relative;
}

.pre-header--close {
  @extend .btn;
  width: 30px; height: @width;
  border-radius: 50%;
  background: $white;
  box-shadow: 0 2px 8px rgba(#000, .15);
  position: absolute;
  right: 0; top: 50%;
  transform: rotate(-45deg) translate(50%, -50%);

  &:before, &:after {
    content: '';
    display: block;
    background: $blue;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    widtH: 15px; height: 1px;
  }

  &:after {
    width: 1px; height: 15px;
  }
}


// Header

.header {
  position: relative;
  background: url(/i/header-pattern.jpg) 50% 0;
  font-size: 2rem;

  @media $desktop {
    height: $header-height;
  }

  @media $tablet {
    height: $tablet-header-height;
  }

  @media $mobile {
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0;
    height: $mobile-header-height;
    padding-bottom: 40px;
    background: none;
    background-color: #F7F7F7;

    .menu-is-opened & {
      position: fixed;
      left: 0; top: 0;
      width: 100%;
      z-index: 100;
    }
  }

  &, a {
    color: #000;
  }

  > .wrapper {
    /* width: 100%; */
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;
    align-items: center;
    grid-template-areas: "logo content content"\
                         "logo menu basket";

    @media $handheld {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media $mobile {
      height: $mobile-header-height;
    }
  }
}

.header--content {
  /* grid-column: 2 / 4;
  grid-row: 1 / 2; */
  grid-area: content;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  padding-top: 10px;

  a {
    text-decoration: none;
  }
}

.header--group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  padding-top: 5px;
  padding-bottom: 3px;
  min-height: 50px;
  white-space: nowrap;

  @media $small-notebook {
    padding-left: 10px;
    padding-right: 10px;
  }

  &--link {
    display: flex;

    &--icon {
      margin-right: 10px;
    }
  }
}

.header--basket-block {
  /* grid-column: 3 / 4;
  grid-row: 2 / 3; */
  grid-area: basket;
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
  gap: 10px;

  @media $desktop {
    margin-left: 0.5vw;
    margin-bottom: 14px;
  }

  @media $mobile {
    margin-left: 0;
    width: 35%;
    height: 30px;
  }
}

.header--left {
  @media $not-mobile {
    width: calc(50% - 75px - 2vw);

    @media $tablet {
      display: flex;
      align-items: center;
    }

    @media $small-handheld {
      width: calc(58% - 75px - 2vw);
    }
  }
}

.header--right {
  @media $not-landscape-mobile {
    @media $desktop {
      width: calc(50% - 75px - 5vw);
    }

    @media $tablet {
      width: calc(50% - 50px - 10vw);
      margin-right: 5vw;
    }

    @media $small-handheld {
      width: calc(50% - 75px - 2vw)
      margin-right: 2vw;
    }
  }
}

.header--logo {
  display: block;
  background: url(/i/oboima-logo.svg) no-repeat 50% / contain;
  font-size: 0;

  /* grid-column: 1 / 2;
  grid-row: 1 / 3; */
  grid-area: logo;

  @media $desktop {
    margin-right: 10px;
  }

  /* @media $not-mobile {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  } */

  @media $desktop {
    width: 104px; height: @width;
    /* width: width('i/oboima-logo.svg'); height: height('i/oboima-logo.svg'); */
    top: 20px;
  }

  @media $tablet {
    width: 97px; height: 96px;
    top: 50%;
    /* transform: translate(-50%, -50%); */
  }

  @media $mobile {
    width: 45px; height: 44px;
  }

  @media $landscape-mobile {
    @media $not-mobile {
      /* margin-left: -30px; */
    }
  }
}

.header--phones {
  display: inline-block;
  text-align: left;
  line-height: 1.35;

  .item {
    white-space: nowrap;
    a {
        cursor:pointer!important;
        pointer-events: inherit!important;
    }
  }

  .item-mobile {
    display: none;
  }

  @media $desktop {
    font-size: 2.2rem;
  }

  @media $tablet {
    font-size: 2rem;
  }

  @media $portrait-tablet {
    font-size: 1.8rem;
  }

  @media $mobile {
    font-size: 1.8rem;
    align-items: center;
    line-height: 1;
    gap: 20px;
  }

  @media $mobile {
    > .item {
      display: none;
    }
    > .item-mobile {
      display: block;
      a {
        display: block;
      }
    }
  }

  @media $small-mobile {
    align-self: center;
    margin-top: -9px;
    display: flex;
  }
}

.header--messengers {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .item {
    display: block;
    text-decoration: none;
    width: 30px; height: 30px;
  }

  .item + .item {
    margin-left: 25px;

    @media $small-mobile {
      margin-left: 5px;
    }
  }

  .item_whatsapp_phone {
    margin-left: 10px
    cursor: pointer!important;
    pointer-events: inherit!important;
  }

  .item_whatsapp {
    margin-left: 10px;
  }

  @media $mobile {
    .item_whatsapp {
      margin: 0;
    }
    > .item_whatsapp_phone {
      display: none;
    }
  }

  for $messenger in 'viber' 'telegram' {
    .item_{$messenger} {
      background: url('/i/img/social/' + $messenger + '.png') no-repeat 50% / contain;
    }
  }

  @media $small-mobile {
    margin-top: 0;
  }
}

.header--email {
  margin-top: 24px;
  padding-left: 26px;
  margin-left: 7px;
  position: relative;

  @media $handheld {
    margin-top: 18px;
    margin-left: 17px;
  }

  @media $landscape-mobile {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: width('i/email-icon.svg'); height: height('i/email-icon.svg');
    background: url(/i/email-icon.svg) no-repeat 50%;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
  }
}

.header--left-bottom {
  display: flex;
  justify-content: space-between;

  a[href] {
    text-decoration: none;
    border-bottom: 1px dashed $gray;
  }
}

.header--worktime {
  padding-left: 32px;
  /* padding-top: 2px; */
  background: url(/i/time-icon-blue.svg) no-repeat 4px 50% / 19px auto;
}

.header--callback-link {
  margin-left: 32px;
  position: relative;
  margin-top: 12px;

  &:before {
    content: '';
    display: block;
    width: width('i/callback-icon.svg'); height: height('i/callback-icon.svg');
    background: url(/i/callback-icon-blue.svg);
    position: absolute;
    left: -@margin-left; top: 50%;
    transform: translateY(-50%);
  }

  @media $tablet {
    font-size: 0;
    width: 40px; height: @width;
    border-radius: 50%;
    background: $blue url(/i/callback-icon-white.svg) no-repeat 35% 50%;
    box-shadow: 0 4px 20px rgba(#000, .25);

    &:before {
      display: none;
    }
  }

  @media $landscape-mobile {
    display: none;
  }
}

.header--link-with-dropdown {
  position: relative;
  z-index: 10000;

  .dropdown {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    left: -20px; top: 100%;
    margin-top: 10px;
    z-index: -1;
    background: $white;
    opacity: 0;
    transition: all .25s ease;
    /* width: calc(100% + 40px); */

    &:before {
      content: '';
      display: block;
      width: 100%; height: 100%;
      padding: 35px 0 0;
      background: $white;
      position: absolute;
      top: -35px; left: 0;
      z-index: -1;
      box-shadow: 0 0 20px rgba(#000, .15);
    }

    li {
      list-style: none;
    }

    li a {
      display: block;
      line-height: 1.2;
      padding-left: 48px;
      padding-top: 7px;
      padding-bottom: 10px;
      padding-right: 20px;
      border: none;
      transition: background-color .25s ease;

      &:hover {
        background: #e0e0e0;
      }
    }
  }

  &:hover {
    .dropdown {
      max-height: 300px;
      opacity: 1;
      overflow: visible;
    }
  }
}

.header--info-link {
  padding-left: 30px;
  padding-right: 22px;
  background: url(/i/info-icon-blue.svg) no-repeat 2px 50% / 19px auto;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 6px; height: 6px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    position: absolute;
    top: 50%; right: 0;
    transform: rotate(-45deg) translateY(-50%);
    margin-top: -1px;
  }
}

.header--right-top,
.header--right-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--right-top {
  a[href] {
    text-decoration: none;
    border-bottom: 1px dashed $gray;
  }
}

@media $desktop {
  .header--right-bottom {
    margin-top: 40px;
  }
}

.header--address-link {
  margin-left: 30px;
  position: relative;

  &:before {
    content: '';
    display: block;
    /* width: width('i/address-icon.svg'); height: height('i/address-icon.svg'); */
    width: 23px; height: 22px;
    background: url(/i/pin-icon.svg) no-repeat 0 50% / contain;
    position: absolute;
    left: -@margin-left; top: 50%;
    margin-left: 4px;
    transform: translateY(-50%);
    margin-top: 2px;
  }
}

.header--profile-link {
  margin-left: 30px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: width('i/profile-icon.svg'); height: height('i/profile-icon.svg');
    background: url(/i/profile-icon-blue.svg) no-repeat 0 50%;
    position: absolute;
    left: -@margin-left; top: 50%;
    transform: translateY(-50%);
    margin-top: 2px;
  }
}

a.header--logout-link {
  color: #828282;
}

.header--search,
.mobile-menu--search {
  position: relative;
  align-self: flex-start;
  flex: 1;

  @media $small-notebook {
    padding-left: 10px;
  }

  @media $desktop {
    max-width: 430px;
  }

  @media $tablet {
    max-width: 160px;
    margin-left: 5vw;
    align-self: center;
  }

  @media $small-handheld {
    max-width: 80px;
  }

  .inp {
    border: none;
    border-bottom: 1px solid black;
    background: transparent;
    line-height: 30px;
    padding: 0 30px 0 0;
    font-size: 1.6rem;
    color: #000;

    &::placeholder {
      opacity: 1;
      color: #000;
    }

    @media $tablet {
      font-size: 1.4rem;
      padding-left: 12px;
    }

    @media $small-mobile {
      text-align: right;
      padding-right: 40px;
    }
  }

  .search-loading-gif {
    background: url(/i/ajax/ajax.gif) no-repeat 100% 50% / 25px auto;
    height: 27px;
    width: 25px;
    position: absolute;
    right: 28px;
    top: -1px;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }

  .ui-autocomplete-loading {
    + .search-loading-gif {
      opacity: 1;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    //background: url(/i/search-icon-blue.svg) no-repeat 100% 50%;
    font-size: 0;

    @media $small-mobile {
      background-size: contain;
    }
  }

  @media $mobile {
    position: absolute;
    left: 10px;
    top: calc(50% + 7px);
    width: calc(100% - 20px);
    max-width: none;
  }
}

.header--search {
  width: calc(100% - 130px);
  left: auto;
  right: 10px;

  .inp {
    text-align: left;
    padding-right: 0;
  }

  .inp::placeholder {
    font-size: 16px;
    color: #828282;
    padding-left: 5px;
  }

  @media $small-mobile {
    width: calc(100% - 70px);
    left: 10px;
  }
}

#additional-search {
  word-break: break-word;
  overflow: hidden;
  z-index: 1010;
  .suggested-word {
    text-decoration: underline;
    cursor: pointer;
    color: #66b6f8;
    font-style: italic;
  }
}

.mobile-menu--search {
  max-width: 100%;
  margin-top: 35px;

  + .mobile-menu--footer {
    margin-top: 35px;
  }
}

.header--basket {
  box-sizing: border-box;
  display: block;
  line-height: 40px;
  font-size: 1.5rem;
  padding: 0 27px 0 55px;
  border-radius: 30px;
  position: relative;
  transition: all .25s ease;

  @media $small-handheld {
    font-size: 1rem;
  }

  @media $mobile {
    width: 31px;
    height: @width;
    line-height: @height;
    font-size: 0;
    padding: 0;
    border: none;
    background: none;
  }

  @media $landscape-mobile {
    @media $not-mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &:not(.is-filled) {
    border: 1px solid $blue;
    background: url(/i/cart-icon-blue.svg) no-repeat 23px 50%;
    color: #000;

    @media $mobile {
      background-position: 50%;
      background-size: auto 65%;
    }
  }

  &.is-filled {
    background: $blue url(/i/cart-icon.svg) no-repeat 23px 50%;
    box-shadow: 0 4px 20px rgba(#000, .25);
    color: $white;
    text-decoration: none;

    &:hover {
      background-color: $dark-blue;
    }

    @media $mobile {
      //background-position: 50%;  background-size: auto 65%;
      background: none;
      box-shadow: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.header-counter-prototype {
  width: 27px; height: @width;
  line-height: @height;
  text-align: center;
  background: $red;
  color: $white;
  font-size: 1.5rem;
  border-radius: 50%;
  position: absolute;

  @media $mobile {
    width: 23px; height: @width;
    line-height: @height;
    font-size: 1.3rem;
    margin-top: 2px;
  }
}

.header--basket-counter {
  @extend .header-counter-prototype;
  top: -10px; right: -10px;
}

.header--favorites {
  display: block;
  width: width('i/heart-icon.svg'); height: height('i/heart-icon.svg');
  //background-image: url(/i/heart-icon-contour.svg);
  position: relative;
  background-repeat: no-repeat;

  &.is-filled {
    background-image: url(/i/heart-icon.svg);

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: url(/i/heart-icon-dark.svg);
      opacity: 0;
      transition: opacity .25s ease;
    }

    &:hover:after {
      opacity: 1;
    }
  }
}

.header--favorites-counter {
  @extend .header-counter-prototype;
  top: -10px; right: -20px;
  z-index: 2;

  @media $small-mobile {
    top: 3px; right: -12px;
  }
}

.mobile-menu-link {
  display: block;
  width: 40px; height: 40px;
  position: relative;

  @media $not-small-handheld {
    margin-left: 3vw;
  }

  @media $small-handheld {
    @media $not-small-mobile {
      margin-left: 1vw;
    }
  }

  @media $small-mobile {
    margin-left: -5px;
  }

  .bars {
    top: 50%;
    margin-top: -1px;
    pointer-events: none;

    &, &:before, &:after {
      display: block;
      height: 2px;
      background: #000;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all .25s ease;

      @media $not-mobile {
        width: 34px;
      }

      @media $mobile {
        width: 25px;
      }
    }

    &:before, &:after {
      content: '';
    }

    &:before {
      @media $not-mobile {
        bottom: 12px;
      }

      @media $mobile {
        bottom: 7px;
      }
    }

    &:after {
      @media $not-mobile {
        top: 12px;
      }

      @media $mobile {
        top: 7px;
      }
    }

    .menu-is-opened & {
      background: transparent;

      &:before {
        transform: rotate(-45deg) translateX(-50%);
        transform-origin: left bottom;
        bottom: 50%;
        margin-bottom: -1px;
      }

      &:after {
        transform: rotate(45deg) translateX(-50%);
        transform-origin: left top;
        top: 50%;
      }
    }
  }

  .auth-page & {
    @media only screen and (max-width: 1275px) and (min-width: 900px) {
      position: fixed;
      margin-left: 0;
      top: 20px;
      left: 20px;
      z-index: 11;
    }
  }
}


// Footer

.footer--diadoc {
  display: block;
  text-align: center;
  margin-top: 10px;

  @media $handheld {
    display: inline-block;
    text-align: left;
  }
}

@media $tablet {
  .footer .wrapper {
    margin-left: 5vw;
    margin-right: 5vw;
    position: relative;
  }
}

.footer--content {
  padding-top: 55px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;

  @media $mobile {
    flex-direction: column;
    padding-top: 25px;
    max-width: 225px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media $tablet {
  .footer--column {
    width: 33.33%;
  }

  .footer--column_middle {
    padding-bottom: 350px;
  }

  .footer--column_menu {
    position: absolute;
    bottom: 140px; left: 33.33%;
    padding-left: 40px;
  }

  .footer--column_rating {
    position: absolute;
    left: 0; bottom: 40px;
  }
}

@media $mobile {
  .footer--column_rating {
    text-align: center;
    margin-top: 20px;

    img {
      max-width: 112px;
    }
  }
}

@media $small-handheld {
  .footer--column_middle {
    margin-right: 20px;
  }
}

.footer--column_justify {
  display: flex;
  flex-direction: column;

  @media $desktop {
    justify-content: space-between;
  }

  @media $tablet {
    justify-content: flex-start;
  }
}

$footer-phones-height = 110px;

@media $mobile {
  .footer--group_phones {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.footer--phones {
  font-size: 2.4rem;

  @media $not-mobile {
    /* min-height: $footer-phones-height; */
    margin-top: -5px;
    padding-bottom: 5px;
  }

  @media $portrait-tablet {
    font-size: 1.9rem;
  }

  @media $mobile {
    font-size: 1.8rem;
  }

  .item {
    white-space: nowrap;
    a {
    cursor: pointer;
    pointer-events: inherit;
    }
  }

  .item + .item {
    margin-top: 3px;
  }
}

.footer--emails {
  margin-top: 1.5em;

  @media $mobile {
    flex-basis: 100%;
    margin-bottom: 1em;
  }
}

.footer--callback {
  a {
    display: inline-block;
    text-decoration: none;
    padding-left: 33px;
    background: url(/i/callback-icon-blue.svg) no-repeat 0 60%;

    @media $mobile {
      display: block;
      padding: 0;
      font-size: 0;
      width: 40px; height: 40px;
      border-radius: 50%;
      background: $blue url(/i/callback-icon-white.svg) no-repeat 30% 50%;
    }
  }
}

.footer--socials {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media $tablet {
    margin-top: 30px;
  }

  @media $mobile {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .item {
    display: block;
    width: 35px; height: 35px;
    background-position: 50%;
    background-repeat: no-repeat;
    transition: opacity .25s ease;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      opacity: 0;
      transition: opacity .25s ease;
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }

  .item + .item {
    margin-left: 30px;
  }

  $socials-list = fb vk inst dzen pint;
  for $social in $socials-list {
    .item_{$social} {
      background-image: url('/i/img/social/' + $social + '.png');
      width: 35px;
      display: inline-block;
      content: "";
      height: 35px;
      background-size: contain;

      //&:after {
      //  background-image: url('/i/img/social/' + $social + '.png');
      //  width: 35px;
      //  display: inline-block;
      //  content: "";
      //  height: 35px;
      //  background-size: contain;
      //}
    }
  }

  .item:hover:after {
    opacity: 1;
  }
}

.footer--address,
.footer--worktime {
  padding-left: 40px;
}

.footer--address {
  min-height: $footer-phones-height;
  background: url(/i/address-icon-blue.svg) no-repeat;
}

.footer--worktime {
  background: url(/i/time-icon-blue.svg) no-repeat;
}

.footer--copyright {
  background: #000;
  font-size: 1.2rem;

  @media $desktop {
    padding: 10px 0 12px;
  }

  @media $tablet {
    padding: 20px 0 22px;
  }

  .wrapper {
    display: flex;
    /* justify-content: space-between; */

    @media $mobile {
      flex-direction: column;
      text-align: center;
      padding: 15px 20px 20px;

      &, a {
        color: #828282;
      }
    }
  }

  .icon_shogo {
    width: width('i/shogo-logo.svg'); height: height('i/shogo-logo.svg');
    background: url(/i/shogo-logo.svg) no-repeat 50%;
    vertical-align: middle;
    margin-top: -3px;
  }
}

.footer--copyright-item + .footer--copyright-item {
  @media $not-mobile {
    margin-left: 30px;
  }

  @media $mobile {
    margin-top: 1em;
  }
}


// Rotation warning

.rotate-device-warning {
  display: none;
  background: $black url(/i/rotate-device.png) no-repeat 50% 40%;
  width: 100%; height: 100%;
  position: fixed;
  left: 0; top: 0;
  z-index: 10000;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  color: $white;
  text-align: center;

  span {
    position: absolute;
    left: 50%; bottom: 20px;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  @media only screen and (max-device-width: 736px) and (min-device-width: 480px) and (orientation: landscape) {
    display: block;
  }
}

@media only screen and (max-device-width: 736px) and (min-device-width: 480px) and (orientation: landscape) {
  body {
    height: 100vh;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      width: 100%; height: 200%;
      background: $black;
      position: fixed;
      left: 0; top: 0;
    }
  }
}


// Lift

.go-top-btn.is-visible {
  opacity: 1;
  max-height: 48px;
}

.go-top-btn {
  width: 48px; height: 48px;
  position: fixed;
  right: 15px; bottom: 15px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  cursor: pointer;
  background: rgba($blue, .5);

  &:hover {
    background: rgba($blue, .8);
  }

  &:before {
    content: '';
    display: block;
    width: 18px; height: 18px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    position: absolute;
    left: 50%; top: 50%;
    transform: rotate(-45deg) translate(-50%,-50%);
    transform-origin: center top;
    margin-left: -3px;
  }
}
.item_whatsapp_default {
      background: url('/i/img/social/whatsapp.png') no-repeat 50% / contain;
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 50%;
      margin-bottom: -3px;
      text-decoration: none;
}
.item_whatsapp_invoice {
      background: url('/i/whatsapp-invoice.png') no-repeat 50% / contain;
      width: 150px;
      height: 50px;
      vertical-align: middle;
      border-radius: 0;
}
.faq-body {
    height: 0;
    transition: .2s linear;
    overflow:hidden;
}
.main-page-faq-link {
     max-width: 320px;
     margin-top: 30px;
     a {
         display: block;
         width: 220px;
         padding: 10px 35px;
         color:#fff;
     }
     background: #97cdfa;
     outline:none;
     border:none;
     display:flex;
     justify-content:center;
     align-items:center;
    &:hover {
        opacity: .9;
    }
}
.home-faq-block {
    background: #f1f0f5;
    cursor: pointer;
    padding: 20px 20px 20px 20px;
    span {
        font-weight: 300;
        color: #4f4f4f;
    }
}
.home-faq-text {
    padding-top: 15px;
}
.home-faq-section--caption {
    font-size: 4.2rem;
    font-weight: 300;
}

.grecaptcha-badge {
  visibility: hidden;
}

.back-btn {
  @extend .btn;
  bordered-btn(1px, #bdbdbd);
  padding: 0 25px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 1.6rem;
  height: 40px;
  line-height: 38px;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;

  .back-btn-arrow {
    content: '';
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    border-radius: 1px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    margin-top: 5px;
  }
}