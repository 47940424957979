body {
  .yandex_href {
    text-align: right;
    * {
      line-height: 1.2;
      color: #9c9c9c;
    }
  }

  .yandex_widget_hrefs {
    display: flex;
    justify-content: space-between;

    @media $small-mobile {
      flex-direction: column;

      * {
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .reviews-slider-out {
    position: relative;
    .s-arrow {
      background-color: #fff;
      border: 1px solid rgb(222, 221, 220);
      overflow: hidden;
      border-radius: 50%;
      position: absolute;
      width: 50px;
      height: 50px;
      cursor: pointer;
      z-index: 50;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      top: calc(50% - 25px);

      a {
        text-decoration: unset;
        font-size: 32px;
        line-height: 1;
        margin-bottom: 3px;
        color: #000;
      }

      &:hover{
        background-color: #5ca5e1;
        a{
          color: #fff;
        }
      }

    }

    .s-arrow.left {
      left: 15px;
    }

    .s-arrow.right {
      right: 15px;
    }
  }

  .reviews-widget {

    .reviews-page {
      .review {
        max-width: unset !important;
        min-height: unset !important;
      }

      .yiiPager {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .previous, .next {
          a {
            border: unset;
            display: block;
            font-size: 1.8rem;
            text-decoration: none;
            text-align: center;
            background-color: #97cdfa;
            width: auto;
            height: auto;
            background: none !important;
            color: #97cdfa;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-radius: 0;
            line-height: 1.2;
            border-bottom: 1px solid transparent;
            -webkit-transition: all .25s ease;
            transition: all .25s ease;

            font-weight: unset;
            padding: 12px;
          }
          a:hover {
            text-decoration: underline;
          }
        }
        .page a {
          display: block;
          font-size: 1.8rem;
          text-decoration: none;
          -webkit-transition: all .25s ease;
          transition: all .25s ease;
          width: 47px;
          height: 47px;
          line-height: 47px;
          text-align: center;
          border-radius: 50%;
          background: #97cdfa;
          color: #fff;
          -webkit-box-shadow: 0 4px 20px #bdbdbd;
          box-shadow: 0 4px 20px #bdbdbd;
          border: unset;
        }
        .page.selected, .page.active, .page:hover {
          a {
            background: #5ca5e1;
            color: #000;
          }
        }
        li {
          margin-right: 15px;
        }
      }
    }

    padding: 25px 0;
    margin-bottom: 0;
    position: relative;

    .subtitle-reviews {
      text-align: center;
      margin-bottom: 15px;
    }

    .short-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .math-rating {
      position: relative;
      font-weight: normal;
      line-height: 1.2;
      font-size: 2.4rem;
      color: rgb(156, 156, 156);
    }

    .slick-arrow {
      display: none !important;
    }

    .review-slide {
      width: 100%;
      padding: 5px;

      .review {
        padding: 30px 20px;
        border: 1px solid rgb(222, 221, 220);
        max-width: 430px;
        width: unset;
        margin: 0 5px;
        min-height: 300px;

        .top-info {
          margin-bottom: 25px;

          .info {

            .date {
              color: rgb(156, 156, 156);
              margin-bottom: 0;
            }

            .r-name {
              font-weight: bold;
            }

            .reviews-star {
              svg {
                margin-right: 0px;
                max-height: 15px;
              }
            }

            & > * {
              line-height: 1.2;
              margin-bottom: 5px !important;
            }

          }
        }

        .comments {

          * {
            line-height: 1.4;
          }

          .b-comment {
            margin-bottom: 10px;
            color: rgb(92, 92, 92);

            &:last-child {
              margin-bottom: 0;
            }

            .more {
              font-size: 16px;
              line-height: 1;
              margin-top: 3px;
              text-align: left;
              color: rgb(156, 156, 156);
              display: block;

              &:hover {
                color: #000;
              }
            }

            .more.hide {
              display: none;
            }
          }

          .r-type {
            font-size: 16px;
            font-weight: bold;
            color: rgb(156, 156, 156);
            margin-bottom: 0px;
          }

          .comment {
            font-size: 16px;
          }
        }

        @media screen and (max-width: 1348px) {
          max-width: 350px;
        }
        @media screen and (max-width: 1024px) {
          max-width: 450px;
        }
      }
    }
  }
}

