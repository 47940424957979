
/*   -=========== Order ===========-   */

.cross {
        position: relative;
    }
.cross::before, .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
}
.cross::before {
    border-bottom: 1px solid red;
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
}
.cross::after {
    border-bottom: 1px solid red;
    -webkit-transform: skewY(10deg);
    transform: skewY(10deg);
}

.empty-basket-page .breadcrumbs {
  margin-bottom: 20px;
}

.empty-basket-page--button {
  @extend .btn, .blue-btn, .h40btn, .rounded-btn;
  padding: 0 20px;
  font-size: 1.6rem;
}


.order-page--caption {
  @extend .h2, .caption-prototype;
  margin-bottom: 30px;

  @media $small-mobile {
    margin-bottom: 20px;
  }
}

.order-page--content {
}

.order-steps-container {
  overflow: hidden;

  @media $mobile {
    display: none;
  }
}

.order-steps {
  @extend .order-page--content;
  display: flex;
  margin-bottom: 35px;

  .item {
    line-height: 50px;
    text-align: right;
    list-style: none;
    font-size: 2;
    position: relative;

    &.is-active {
      a, span {
        background: $blue;
        color: $white;
      }

      a:after, span:after {
        background: $blue;
      }
    }

    &:not(.is-active) {
      a, span {
        background: #d9dfe7;
        color: #828282;
      }

      a:after, span:after {
        background: #d9dfe7;
      }
    }

    a {
      text-decoration: none;
    }

    a, span {
      display: block;
      margin-right: 30px;
      padding-left: 50px;
      padding-right: 25px;
      position: relative;

      @media $landscape-mobile {
        & {
          padding-left: 40px;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 35px; height: @width;
        position: absolute;
        left: 100%; top: 50%;
        transform: rotate(45deg) translateY(-50%);
        transform-origin: top center;
        margin-left: -17px;
        z-index: -1;
      }
    }
  }

  .item:first-child {
    margin-right: -30px;
    z-index: 2;
    flex: 1;

    &:before {
      content: '';
      display: block;
      width: 50vw; height: 100%;
      position: absolute;
      right: 100%; top: 0;
    }

    &.is-active:before {
      background: $blue;
    }

    &.is-active a:after {
      background: $white;
      width: 38px; height: @width;
    }

    &.is-active a:before {
      content: '';
      display: block;
      width: 35px; height: @width;
      position: absolute;
      left: 100%; top: 50%;
      transform: rotate(45deg) translateY(-50%);
      transform-origin: top center;
      margin-left: -18px;
      z-index: 0;
      background: $blue;
    }
  }
}

.order-table {

  .order-price-wrapper {
    position: relative;
  }
  @media $not-small-mobile {
  }
  .table-up {
    border-bottom: 2px solid #e0e0e0;
  }
  th {
    font-size: 2.1rem;
    font-weight: normal;
    color: #4f4f4f;
    padding-bottom: 10px;
    

    @media $small-mobile {
      display: none;
    }
  }

  th:first-child {
    text-align: left;
  }
  .product--price {
    font-size: 2rem;
  }
  .product {
    font-size: 1.8rem;
    font-weight: 500;
    .product--description {
      width: 350px
    }
    td {
      @media $not-small-mobile {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #828282;
      }

      @media $small-mobile {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media $small-mobile {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 30px;
      position: relative;

      + .product {
        padding-top: 40px;
        border-top: 1px solid $black;
      }
    }
  }

  .product--image {
    @media $not-small-handheld {
      width: 250px;
    }

    @media $portrait-tablet {
      width: 110px;
    }

    @media $small-mobile {
      width: 100%;
      text-align: center;
    }
  }

  .product--description {
    @media $not-small-mobile {
      padding-left: 20px;
      vertical-align: top;
    }

    @media $small-mobile {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }

  .product--name {
    @media $not-small-mobile {
      font-size: 1.8rem;
      margin-bottom: 20px;
    }

    @media $small-mobile {
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 3px;
    }
  }

  .product--params {
    padding-top: 20px;
    padding-left: 20px;

  }

  .product--params,
  .product--color-mobile {
    font-size: 1.4rem;

    .item + .item {
      margin-top: 0.5em;
    }

    .item--label,
    .item--value {
      display: inline;
      width:165px;
    }

    .item_color {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;

      .item--value {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .item--color {
      width: 35px;
      height: 20px;
      border-radius: 25px;
      flex-shrink: 0;
    }
  }

  .product--price,
  .product--amount,
  .product--controls {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

  }

  .order-card{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media $not-small-mobile{
      height: 300px;
    }
    @media $small-mobile {
      height: 55px;
    }
  }

  .dop-tovar{
    @media $not-small-mobile {
      display: block;
    }

    @media $small-mobile {
      display: none;
    }
  }

  .dop-product-hr{
    @media $small-mobile {
      width: 100%;
      height: 30px;
    }
    hr{
      background: #97cdfa;
      @media $not-small-mobile {
        display: none;
      }
    }
  }


  .product--price {
    white-space: nowrap;
  }

  .product--remove-button {
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    height: 35px;
    font-size: 0;
    background: url("/i/trash-mobile.svg") no-repeat;


    @media $small-mobile {
      background: $red;
      border-radius: 50%;
      position: absolute;
      right: -5px; top: -15px;

      &:before, &:after {
        background: $white;
      }
    }
  }

  @media $small-mobile {
    .product + .product .product--remove-button {
      top: 25px;
    }

    .product--price,
    .product--amount {
      box-sizing: border-box;
      width: 33.33%;
    }

    .spinner {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .product--mobile-label {
    font-size: 1.3rem;
    color: #828282;
    margin-bottom: 10px;
  }
}

.product--params {
  @media $small-mobile {
    display: none;
  }
}

.product--color-mobile {
  margin-top: 25px;
  padding-left: 20px;

  @media $not-small-mobile {
    display: none;
  }
}


.order-page--footer {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
   & > .order-page--group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  @media $small-mobile {
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $black;
    padding-top: 20px;

    .order-page--group {
      text-align: center;
      justify-content: center;
    }
  }
}

.order-page--footer-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  &--first-step {
    width: 100%;
  }
  @media $small-mobile {
    &--first-step {
      flex-direction: column-reverse;
    }
  }

  .order-page--discount-block {
    font-size: 1.3rem;
    background-size: 54px auto;
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    margin-top: 0;
    background-position: 0 50%;

    &--first-step {
      font-size: 2rem;
      padding-right: 15px;
      line-height: 1.5;

      @media $small-mobile {
        font-size: 1.5rem;
        padding-right: 0;
      }
    }
  }
}

.order-page--back-link {
  @extend .btn, .gray-bordered-btn, .rounded-btn;
  line-height: 40px;
  padding: 0 25px 0 45px;
  font-size: 1.6rem;
  color: #828282;
  position: relative;
  //margin-top: 10px;

  &:before {
    content: '';
    display: block;
    width: 6px; height: @width;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    position: absolute;
    left: 20px; top: 50%;
    transform: rotate(-45deg) translateY(-50%);
  }

  @media $small-mobile {
    order: 2;
    margin-top: 20px;

    + .order-page--group {
      order: 1;
    }
  }
}

.order-page--footer-price {
  //margin-bottom: 25px;
}

@media $not-small-mobile {
  .order-page--footer-price-label,
  .order-page--footer-price-value {
    display: inline;
  }
}

.order-page--footer-price-label {
  @media $not-small-mobile {
    font-size: 2.4rem;
  }

  @media $small-mobile {
    font-size: 1.6rem;
  }
}

.order-page--footer-price-value {
  @media $not-small-mobile {
    font-size: 3.6rem;
    margin-left: 10px;
  }

  @media $small-mobile {
    font-size: 2.4rem;
    margin-top: 5px;
  }
}

.order-page--footer-button {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 44px;
  font-size: 2.2rem;
  padding: 0 25px;
  box-shadow: 0 4px 20px rgba(#000, .25);

  @media $landscape-tablet {
    line-height: 35px;
  }

  @media $small-mobile {
    box-sizing: border-box;
    width: 218px;

    &--first-step {
      margin-bottom: 20px;
    }
  }
}


.order-page--form-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .order-form {
    margin-bottom: 40px;

    .inp {
      font-size: 1.8rem;
      border: none;
      border-radius: 5px;
      color: #2f2f2f;
    }
    @media $not-small-handheld {
      width: 100%;
    }

    @media $portrait-tablet {
      width: 264px;
    }

    @media $small-mobile {
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      width: 100%;
    }
  }
  @media $mobile {
    flex-direction: column;
  }
}

.order-page--discount-block {
  margin-top: 20px;
  padding-left: 165px;
  padding-top: 35px;
  padding-bottom: 35px;
  background: url(/i/discount-pic.svg) no-repeat 0 50%;
  font-size: 2.4rem;
  line-height: 1.5;

  @media $portrait-tablet {
    //width: calc(100% - 300px);
    font-size: 1.8rem;
    text-align: center;
    padding-top: 115px;
    padding-left: 0;
    background-position: 50% 0;
    background-size: auto 100px;
  }

  @media $mobile {
    display: none;

    &--first-step {
      display: block;
    }
  }
}


.order-success {
  box-sizing: border-box;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 45px;

  @media $not-small-mobile {
    padding-left: 245px;
    background: url(/i/success-pic.svg) no-repeat;
  }

  @media $small-mobile {
    text-align: center;
  }

  p + p {
    margin-top: 1.5em;
  }

  p + .order-success--thanks {
    margin-top: 1em;
  }
}

.order-success--number {
  font-size: 2.4rem;
  font-weight: bold;
}

.order-success--thanks {
  font-size: 2.4rem;
}

.order-success--phones {
  padding: 35px 40px 40px;
  background: #d9dfe7;
  color: #000;

  a {
    font-size: 2.4rem;
    color: #000;
    margin-top: .25em;
  }
}

.order-success--button {
  @extend .btn, .gray-bordered-btn, .rounded-btn, .h40btn;
  padding: 0 20px 0 45px;
  font-size: 1.6rem;
  color: #828282;
  position: relative;
  margin-top: 1em;

  &:before {
    content: '';
    display: block;
    width: 6px; height: @width;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    position: absolute;
    left: 20px; top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    margin-top: -1px;
  }
}

.form-submit {
  .order-page--discount-block {
    display: none;
  }

  @media $mobile {
    .order-page--discount-block {
      display: block;
      background-size: 54px auto;
      padding-left: 70px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: left;
      margin-top: 0;
      background-position: 0 50%;
      line-height: 1.5;
      font-size: 1.5rem;
      padding-right: 0;
    }

    button {
      margin-bottom: 20px;
    }
  }
}

.product-mobile {
  display: inline-block;
  position: relative;
  width: calc(100% - 17px);
  height: fit-content;
  padding: 0 5px 30px 12px;
  color: #000;
  font-size: 18px;
  border-bottom: 1px solid #828282;
  @media $small-mobile {
        padding: 0 5px 14px 12px;
        margin-top: 14px
  }
  .image {
    display: block;
    width: 148px;
    height: 148px;
    float: left;
  }

  .cost-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;
    height: 128px;
    padding: 10px 0;
    margin-left: 30px;
    float: left;
    @media $small-mobile {
        width: calc(100% - 178px);
        position: relative;
      }
    .sum {
      @media $small-mobile {
        position: relative;
      }
    }

    .count-change {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 10px;
      flex: 1;
      align-items: center;
      width: fit-content;
      text-align: center;

      span {
        padding: 0 5px;
      }

      .count-change-btn {
        width: 30px;
        height: 30px;
        background-size: contain;
        border: none;
        cursor: pointer;
      }

      .minus {
        background-image: url(/i/minus.svg);
      }

      .plus {
        background-image: url(/i/plus.svg);
      }
    }
  }

  .remove {
    position: absolute;
    top: 0;
    right: 5px;
    width: 38px;
    height: 35px;
    background-image: url(/i/trash-mobile.svg);
    @media $small-mobile {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .title {
    float: left;
    margin-top: 20px;
    font-size: 16px;
    color: #000;
  }

  .params-wrapper {
    width: 100%;
    float: left;
    font-size: 16px;
    margin-top: 20px;

    .param-wrapper {
      .param {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;

        &:after {
          content:' ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ';
          color: #C3C3C3;
          padding-left: 1px;
          font-size: 12px;
        }  
      }

      & + .param-wrapper {
        margin-top: 5px;
      }
    }
  }
}