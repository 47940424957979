
/*   -=========== Utils ===========-   */

clearfix() {
  &:before, &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.ui-widget-z-index {
  z-index: 9999999999 !important;
}