
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  position: absolute;
  background: $body-bg;
  z-index: 333;
  padding: 45px 50px 30px;
}

.popup--close {
  @extend .btn;
  width: 29px;
  height: 29px;
  background: #d9dfe7;
  border-radius: 50%;
  position: absolute;
  top: 15px; right: 15px;
  transform: rotate(45deg);
  cursor: pointer;

  &:hover {
    background: lighten($red, 10);
  }

  &:before, &:after {
    content: '';
    display: block;
    background: $white;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 15px; height: 1px;
  }

  &:after {
    width: 1px; height: 15px;
  }
}

.popup--caption {
  margin-left: -50px;
  margin-right: -50px;
  font-size: 2.4rem;
  text-align: center;
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 50%;
    z-index: -1;
  }

  > span {
    display: inline-block;
    background: $white;
    padding-left: 17px;
    padding-right: 17px;
  }
}


// Calculator popup

.calculator-popup {
  box-sizing: border-box;
  max-width: 100%;
  width: 440px;
  padding-left: 27px;
  padding-right: 27px;
  color: #000;

  .popup--caption {
    font-size: 2rem;
    margin-bottom: 15px;
    margin-left: -@padding-left;
    margin-right: -@padding-right;
  }

  .calculator-popup--tab {
    box-shadow: 0 -3px 3px rgba(#000, .15);
    padding-left: @padding-left;
    padding-right: @padding-right;
    margin-left: -@padding-left;
    margin-right: -@padding-right;
    padding-top: 30px;
  }

  .form-row + .form-row {
    margin-top: 18px;
  }
}

.calculator-popup--sizes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 15px;

  @media $small-mobile {
    flex-direction: column;
    justify-content: center;
  }

  dt, dd {
    display: inline-block;
  }

  .item:after {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }

  .item_length {
    &:after {
      content: url(/i/height-icon.svg);
    }
  }

  .item_width {
    &:after {
      content: url(/i/width-icon.svg);
    }
  }
}

.calculator-popup--tabs-menu ul {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  li {
    list-style: none;
  }

  li a {
    display: block;
    width: 120px;
    line-height: 30px;
    font-size: 1.4rem;
    text-align: center;
  }

  li:not(.is-active) a {
    background: $blue;
    color: $white;
    cursor: pointer;
  }

  li.is-active a {
    padding-top: 5px;
    background: $white;
    color: #000;
    position: relative;
    box-shadow: 0 -3px 3px rgba(#000, .15);

    &:after {
      content: '';
      display: block;
      width: 100%; height: 10px;
      background: $white;
      position: absolute;
      left: 0; top: 100%;
    }
  }
}

.calculator-popup--result-caption {
  margin-top: 20px;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
}

.calculator-popup--result {
  width: 46px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  margin-bottom: 25px;

  &:focus {
    border-color: #bdbdbd;
  }
}

.calculator-popup--submit-btn {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 44px;
  height: 49px;
  padding: 0 30px;
  font-size: 2.2rem;
  box-shadow: 0 4px 20px rgba(#000, .25);
  margin-bottom: 20px;
}


// Basket success popup

.basket-success-popup {
  box-sizing: border-box;
  padding: 35px 30px 30px;
  width: 520px;

  @media $mobile {
    width: 365px;
    height: 530px !important;
    padding: 20px 12px 15px 20px;
    margin: auto;
    border-radius: 15px;
  }
}

section.not-desktop {
  @media $tablet {
    display: none;
  }
}

.basket-success-popup-icon {
  display: block;
  width: 60px;
  height: 45px;
  float: left;
}

.basket-success-popup-header {
  width: 190px;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  float: left;
  margin-left: 22px;
  color: #000;
}

.basket-success-popup-close {
  display: block;
  width: 50px;
  height: 50px;
  float: right;
}

.basket-success-popup-img-wrapper {
  width: 100%;
  margin-top: 21px;
  float: left;

  img {
    display: block;
    width: 94px;
    height: 94px;
    float: left;
  }

  .basket-success-popup-params-wrapper {
    width: 160px;
    height: 60px;
    margin: 15px 0 0 30px;
    float: left;

    div {
      width: 80px;
      text-align: center;
      color: #000;
    }

    hr {
      width: 160px;
      float: left;
      border: none;
      color: #97CDFA;
      background-color: #97CDFA;
      height: 1px;
    }

    .blind {
      color: #858585;
    }
  
    .left {
      float: left;
    }
  
    .right {
      float: right;
    }
  }
}

.basket-success-popup-cost-wrapper {
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 20px;
  float: left;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 0;

  div {
    width: fit-content;
    height: fit-content;
    color: #000;
  }

  .center {
    margin-left: 40px;
  }

  hr {
    width: 100%;
    border: none;
    color: #97CDFA;
    background-color: #97CDFA;
    height: 1px;
  }

  .blind {
    color: #858585;
  }

  .basket-success-popup-cost-count-wrapper {
    width: 110px;
    height: 50px;
    text-align: center;

    img {
      display: block;
      width: 31px;
      height: 31px;
    }

    .minus {
      float: left;
    }

    .plus {
      float: right;
    }
  }
}

.basket-success-popup-sale {
  display: block;
  width: 85px;
  height: 50px;
  margin-top: 25px;
  float: left;
}

.basket-success-popup-text {
  width: 220px;
  float: left;
  height: fit-content;
  margin: 25px 0 0 25px;
  color: #959595;
  font-size: 16px;
  line-height: 22px;
}

.basket-success-popup-back-wrapper {
  width: 190px;
  height: fit-content;
  margin: 15px 0 0 calc((100% - 204px) / 2);
  float: left;
  color: #8C8C8C;
  cursor: pointer;

  img {
    display: block;
    width: 14px;
    height: 24px;
    float: left;
    margin-right: 15px;
    transform: scale(-1, 1);
  }
}

.basket-success-popup-submit-btn {
  width: 100%;
  height: 45px;
  float: left;
  margin-top: 15px;
  background-color: #97CDFA;
  font-size: 26px;
  line-height: 45px;
  border-radius: 25px;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.basket-success-popup--content {
  padding-left: 235px;
  padding-top: 15px;
  padding-bottom: 25px;
  background: url(/i/success-pic.svg) no-repeat 18px 0 / 156px auto;
  font-size: 1.8rem;
  color: #000;

  @media $mobile {
    padding-left: 0;
    background: none;
  }

  p + p {
    margin-top: .5em;
  }
}

.basket-success-popup--discount {
  line-height: 1.2;
  color: #828282;
  padding-left: 35px;
  position: relative;
  padding-top: .5em;
  padding-bottom: .5em;

  @media $not-small-mobile {
    font-size: 1.4rem;
  }

  &:before {
    content: '%';
    display: block;
    width: 29px; height: 29px;
    line-height: 27px;
    text-align: center;
    background: #d9dfe7;
    color: $white;
    border-radius: 50%;
    font-size: 2.4rem;
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
  }
}

.basket-success-popup--highlighted-text {
  font-size: 2.4rem;
  line-height: 18px * 1.2;
}

.basket-success-popup--controls {
  display: flex;
  justify-content: space-between;

  @media $mobile {
    flex-direction: column;
  }
}

.basket-success-popup--back-button {
  @extend .btn, .gray-bordered-btn, .rounded-btn, .h40btn;
  font-size: 1.6rem;
  color: #828282;
  padding: 0 25px 0 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 6px; height: @width;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    position: absolute;
    left: 20px; top: 50%;
    transform: rotate(-45deg) translateY(-50%);
  }
}

.basket-success-popup--order-button {
  @extend .btn, .blue-btn, .rounded-btn, .h40btn;
  font-size: 2.2rem;
  padding: 0 25px;
  box-shadow: 0 4px 20px rgba(#000, .25);

  @media $not-mobile {
    margin-right: 15px;
  }

  @media $mobile {
    margin-top: 10px;
  }
}


// Fast order

.fast-order-popup {
  max-width: 620px;
  padding-left: 27px;
  padding-right: 27px;

  .popup--caption {
    margin-left: -27px;
    margin-right: -27px;
  }
}

.fast-order-popup--content {
  @media $not-small-mobile {
    display: flex;
  }
}

.fast-order-popup--description {
  @media $not-small-mobile {
    flex-basis: 200px;
    flex-shrink: 0;
  }

  @media $small-mobile {
    margin-bottom: 20px;
  }
}

@media $not-small-mobile {
  .fast-order-popup--form {
    margin-left: 35px;
  }
}

.fast-order-popup--image {
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.fast-order-popup--article {
  text-align: center;
  font-size: 1.6rem;

  a {
    color: #000;
  }
}


// Found cheaper

.found-cheaper-popup {
  max-width: 385px;
  padding-left: 27px;
  padding-right: 27px;

  .popup--caption {
    margin-bottom: 10px;
    margin-left: -27px;
    margin-right: -27px;
  }

  .popup--text {
    text-align: center;
    margin-bottom: 25px;
  }
}

.found-cheaper-popup--footer {
  font-size: 1.2rem;
  color: #000;
  margin-top: 30px;
  text-align: center;
}

.found-cheaper-popup--footer-group {
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  padding-right: 23px;
  padding-left: 23px;

  @media $mobile {
    flex-direction: column;
    align-items: center;
  }

  a[href^="mailto:"] {
    display: inline-block;
    color: #828282;
    text-decoration: none;
    border-bottom: 1px solid #828282;
  }
}

.found-cheaper-popup--terms {
  margin-bottom: -30px;
  margin-left: -27px;
  margin-right: -27px;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #000;
}

.found-cheaper-popup--terms-caption {
  display: block;
  width: 100%;
  background: #d9dfe7;
  line-height: 35px;
  text-align: center;

  &:after {
    content: '';
    display: inline-block;
    width: 8px; height: 8px;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    transform: rotate(-45deg);
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
}

.found-cheaper-popup--terms-content {
  overflow: hidden;
  padding: 0 27px;
  max-height: 0;
  transition: all .25s ease;

  p + p {
    margin-top: .5em;
  }
}

.found-cheaper-popup--terms.is-expanded {
  .found-cheaper-popup--terms-content {
    padding-top: 15px;
    padding-bottom: 20px;
    max-height: 2000px;
  }

  .found-cheaper-popup--terms-caption:after {
    transform: rotate(135deg);
    top: 3px;
  }
}


// Settings

.settings-popup {
  width: 520px;
  max-width: 100%;
  box-sizing: border-box;
}


// Promocode

.promocode-popup-link {
  @extend .btn, .blue-btn, .rounded-btn;
  /* display: none; */
  line-height: 22px;
  padding: 8px 25px;
  font-size: 1.8rem;
  position: fixed;
  bottom: 40px;
  z-index: 100;
  background: #97CDFA;
  transition-property: background-color;
  display: flex;
  align-items: center;

  &__text {
    margin-right 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__img {
    height 36px;
  }


  @media $bigger-than-wrapper {
    left: 'calc(50vw - (%s - 300px) / 2)' % $wrapper-width;

    &--normal {
      left: 'calc(50vw - %s / 2)' % $wrapper-width;
    }
  }

  @media $less-than-wrapper {
    left: 20px;
  }

  @media $small-mobile {
    font-size: 1.8rem;
    line-height: 16px;
    bottom: 20px; left: 50%;
    padding: 8px 20px;

    &__img {
      height 23px;
    }

    &__text {
      text-transform uppercase;

      &--item {
        img {
          margin-bottom: -2px;
        }
      }
    }
  }
}



.promocode-popup-link--roistat {
  cursor: auto;
  &:hover {
    background: #97cdfa;
  }
  @media $bigger-than-wrapper {
    font-size: 2.8rem;
    left: 'calc(50vw - %s / 2)' % $wrapper-width;
    bottom: 48px;
  }
  @media $less-than-wrapper {
    left: 20px;
    bottom: 115px;
  }
  @media $small-mobile {
    left: 15%;
    bottom: 25px;
    font-size: 2.9rem;
  }
}
.containerw {
      position: fixed;
      z-index: 10;
      width: 190px;
      height: 48px;
      bottom: 40px;
      left: 20%;
      padding: 8px 25px;
      border-radius: 30px;
      background-color: #97cdfa;
      text-align: center;

      @media $less-than-wrapper {
        left: calc(100% - 67%);
      }
      
      @media $small-mobile {
        width: 180px;
        height: 30px;
        left: 21%;
        bottom: 30px;
      }
  }

  .video-link {
  padding: 0;
  position: absolute;
  text-align: center;
  opacity: 1;
  left: 0;
  bottom: 0;
  background: none;
  transition: all 1.5s ease-in-out;
  max-width: 250px;
    &:hover {
      background-color: transparent;
    }
  }

  .video-link_link {
      left: 30px;
      bottom: 10px;
      @media $less-than-wrapper {
        font-size: 1.8rem;
      }
      @media $small-mobile {
        bottom: 8px;
        left: 21px;
      }
  }

  .video-link_code {
      left: 65px;
      bottom: 20px;
      @media $less-than-wrapper {
        font-size: 2.8rem;
      }
      @media $small-mobile {
        bottom: 17px;
        left: 36px;
        letter-spacing: 10px;
      }
  }

  .video-link.hide-up {
  opacity: 0;
  pointer-events: none; /* Отключаем клики */
  cursor: default;
  transform: translateY(-100%); /* Уезжает вверх */
  }

  .video-link.hide-down {
  opacity: 0;
  transform: translateY(100%); /* Уезжает вниз */
  }

  .video-link.show {
  opacity: 1;
  transform: translateY(0);
  }

.promocode-popup-link--code {
  line-height: 1.2;
  position: relative;
  top: -10px;
}

.promocode-popup-link--close {
  @extend .btn;
  background: transparent;
  position: absolute;
  left: 100%; top: 50%;
  margin-left: 10px;
  transform: translateY(-50%);
  width: 30px; height: 30px;
  transition-duration: .5s;

  &:hover {
    transform: rotate(180deg) translateY(50%);
  }

  &:before, &:after {
    content: '';
    display: block;
    width: 2px; height: 20px;
    background: $blue;
    position: absolute;
    left: 50%; top: 50%;
    transform-origin: left top;
  }


  &:before {
    transform: rotate(-45deg) translate(-50%, -50%);
  }

  &:after {
    transform: rotate(45deg) translate(-50%, -50%);
  }
}

.promocode-popup {
  box-sizing: border-box;
  width: 796px;
  max-width: 100%;

  @media $small-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }

  .popup--flex-group {
    display: flex;
    justify-content: space-between;
  }

  .promocode-form {
    max-width: 415px;
    margin-bottom: 30px;

    .form-submit {
      margin-top: 1em;

      @media $not-small-mobile {
        text-align: left;
      }
    }
  }

  .popup--caption {
    margin-bottom: 10px;

    @media $small-mobile {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .popup--text {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 25px;

    @media $small-mobile {
      font-size: 1.8rem;
    }
  }

  .popup--subcaption {
    font-size: 3.6rem;
    margin-bottom: 5px;

    @media $small-mobile {
      font-size: 2.2rem;
    }
  }
}

.promocode-popup--icons {
  flex-shrink: 0;
  margin-left: 20px;
  text-align: center;

  @media $small-mobile {
    display: none;
  }

  .item + .item {
    margin-top: 50px;
  }

  img {
    margin-bottom: 10px;
  }

  .item--text {
    font-size: 1.6rem;
  }
}

.promocode-popup--footer {
  padding-top: 25px;
  font-size: 1.2rem;
  color: #000;

  img {
    max-width: calc(100% + 100px);
    margin-left: -50px;
    margin-right: -50px;
    margin-bottom: 20px;

    @media $small-mobile {
      max-width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.promocode-popup--features {
  font-size: 1.4rem;

  li {
    list-style: none;
    padding-left: 28px;
    background: url(/i/mark-icon-dark.svg) no-repeat;
    min-height: 25px;
  }

  li + li {
    margin-top: .5em;
  }
}

.clever_btn_cont {
  left: 12px !important;
  right: auto !important;
}

// card page colors popup styles
.body-palette {
  display: grid;
  grid-template-columns: auto 40%;
  column-gap: 50px;

  @media $small-handheld {
    grid-template-columns: 100%;
  }
}

.colors-popup .popup__close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 20px;
  transform: rotate(45deg);
  z-index: 10;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background: none;
  outline: none;
  user-select: none;
  transition: all 0.25s ease;

  &::before,
  &:after {
    content: '';
    display: block;
    background: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: background-color 0.25s ease;
  }

  &:before {
    width: 19px;
    height: 2px;
  }

  &:after {
    width: 2px;
    height: 19px;
  }
}

.colors-popup .card-page__variants-slider-item {
  display: block;
  flex-basis: calc(9% - 10px);
  flex-shrink: 0;
  text-decoration: none;
  font-size: 0;
  position: relative;
  margin-bottom: 1rem;
  margin-left: 10px;

  @media $notebook-and-less {
    flex-basis: calc(10% - 10px);
  }

  @media $small-handheld {
    flex-basis: calc(12% - 10px);
    scroll-snap-align: start;
  }

  @media $small-mobile {
    flex-basis: calc(20% - 10px);
  }
}

.popup.colors-popup {
  max-width: 1320px;
  width: 90vw;
  box-sizing: border-box;
  padding: 40px;
  padding-top: 25px;

  @media $small-handheld {
    padding: 30px;
    padding-top: 25px;
  }

  @media $mobile {
    padding: 55px 15px 15px;
  }
}

.popup__close.colors-popup__close {
  right: 5px; top: 5px;

  @media $small-mobile {
    top: 15px;
  }
}

.colors-popup__caption {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 50px;
  position: relative;

  @media $not-small-handheld {
    grid-column: 1 / 3;
  }

  @media $small-mobile {
    font-size: 1.8rem;
    margin-bottom: 1.3em;
  }

  span {
    background: $white;
    padding-left: 40px;
    padding-right: 40px;

    @media $mobile {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #828282;
    position: absolute;
    left: 0;
    top: 55%;
    z-index: -1;
  }
}

.colors-popup__pager {
  padding-top: 15px;
  margin-left: 50px;
  margin-right: 50px;

  @media $small-handheld {
    display: none;
  }
}

.colors-popup__pager-status {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0.75em;
}

.colors-popup__search {
  max-width: 90%;
  position: relative;
  margin-bottom: 35px;

  @media $small-handheld {
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.colors-popup__search-input {
  padding: 0.8em 3em 0.8em 1.3em;
  font-size: 1.6rem;
  background: url(/i/search-icon.svg) no-repeat 94% 14px;
  border: 1px solid $gray;
  max-width: 290px;
  width: 100%;

  @media $mobile {
    max-width: unset;
  }
}

.colors-popup__search-reset {
  top: 50%;
  translate: 0 -50%;

  &.is-active {
    display: block;
  }
}

.colors-popup__info {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 35px;

  @media $mobile {
    gap: 25px;
  }

  @media $small-handheld {
    grid-row-start: 4;
    margin: 20px 0 30px;
    flex-direction: column;
    justify-content: center;
  }
}

.colors-popup__selected-name {
  color: $green;
  font-size: 1.6rem;
}

.colors-popup__apply {
  btn();
  font-size: 2.2rem;
  color: $white;
  padding: 15px 35px;
  background-color: $blue;
  border-radius: 30px;
  text-decoration: none;

  @media $mobile {
    padding: 9px 35px;
  }

  &:hover {
    opacity: 0.5;
    color: $white;
  }
}

.colors-popup__variants-list {
  flex-basis: 60%;
  flex-shrink: 0;

  @media $small-handheld {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    margin-bottom: 0;
    grid-row-start: 5;
  }

  @media $mobile {
    margin-top: 0;
  }

  .card-page__variants-slider {
    max-height: 400px;
    overflow-y: auto;

    @media $mobile {
      padding-right: 5px;
    }

    &::-webkit-scrollbar {
      border-right: 1px solid #828282;
      width: 6px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #828282;
    }
  }
}

.colors-popup__selected-color {
  display: block;
  flex-shrink: 0;
  position: relative;

  @media $small-handheld {
    margin-left: -15px;
    margin-right: -15px;
    grid-row-start: 3;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 280px;

    @media $small-handheld {
      padding-bottom: 200px;
    }
  }
}

.colors-popup__colorpicker-block-caption {
  margin-bottom: 1em;
}

.colors-popup .card-page__variants-slider-item-image,
.colors-popup .card-page__variant-thumb {
  transition: all 0.25s ease;
}

.colors-popup .card-page__variants-slider-item-image {
  width: 100%;
}

.colors-popup .card-page__variant-thumb {
  padding-bottom: 47%;
  position: relative;
  border-radius: 25px;
  transition: all 0.25s ease-out;
  border: 3px solid $white;

  &:hover,
  &.selected {
    box-shadow: rgb(130, 130, 130) 0px 0px 0px 1px;
  }
}

.card-page__variant-text {
  font-size: 1.2rem;
  margin-top: 1em;
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
  opacity: 0;
  transition: opacity 0.25s ease;
  display: none !important;
}

.colors-popup .card-page__variants-slider {
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  padding-top: 5px;
}
