
/*   -=========== Tabs ===========-   */

.tabs {
  .tabs-panel {
    display: none;
  }
}


// Default tabs

.tabs_default {
  .tabs-nav {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    li {
      list-style: none;
    }

    li + li {
      border-left: 1px solid $white;
    }

    a {
      display: block;
      line-height: 48px;
      height: 54px;
      background: $blue;
      color: $white;
      font-size: 2.4rem;
      text-decoration: none;
      padding-left: 45px;
      padding-right: 45px;
      position: relative;
      transition: background-color .25s ease;

      @media $small-handheld {
        font-size: 1.6rem;
        height: 44px;
        line-height: 40px;
      }

      @media $small-mobile {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }
    }

    .is-active a {
      padding-top: 8px;
      background: $white;
      color: #000;
      box-shadow: 0 0 10px rgba(#000, .25);

      &:after {
        content: '';
        display: block;
        width: 100%; height: 10px;
        background: $white;
        position: absolute;
        left: 0; top: 100%;
      }
    }

    li:not(.is-active) a:hover {
      background: $dark-blue;
    }
  }

  .tabs-content {
    box-shadow: 0 0 10px rgba(#000, .25);
  }
}
