
/*   -=========== Errors ===========-   */

.error-page-html,
.error-page {
  height: 100%;
}

.error-page {
  background: url(/i/error-bg.jpg) no-repeat 50%;

  #structure {
    height: 100%;
  }

  &.error-404 {

  }

  .error-content {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-text {
    display: flex;
    align-items: flex-start;
    color: $white;

    @media $not-small-mobile {
      transform: translate(20%, 10%);
    }

    @media $small-mobile {
      flex-direction: column;
    }
  }

  .error-code {
    font-size: 0;
    background: url(/i/404.svg) no-repeat 50% / contain;
    width: width('i/404.svg'); height: height('i/404.svg');
    max-width: 100%;
  }

  .error-note {
    font-size: 2.4rem;

    @media $not-small-mobile {
      margin-left: 80px;
      max-width: 200px;
    }

    @media $small-mobile {
      text-align: center;
      margin-top: 30px;
      width: 100%;

      a{
        color: $blue;
      }
    }
  }

  .error-button {
    @extend .btn, .blue-btn, .rounded-btn;
    line-height: 40px;
    padding: 0 25px 0 40px;
    font-size: 1.6rem;
    margin-top: 30px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 15px; height: 10px;
      background: url(/i/left-arrow-icon.svg) no-repeat 50% / contain;
      position: absolute;
      left: 13px; top: 50%;
      transform: translateY(-50%);
    }

    @media $small-mobile {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
