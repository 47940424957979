
/*   -=========== Card ===========-   */

.product-card--caption {
  color: #000;
  text-align: center;
  position: relative;
  margin-bottom: 5px;

  p {
    font-size: 2.1rem!important;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 60%;
    transform: translateY(-50%);
  }

  .h1 {
    display: inline-block;
    background: $white;
    padding: 0 15px;
    position: relative;
  }
}

.art-wrap {
  * {
    color: #000!important;
    font-size: 1.8rem!important;
  }
}

.product-card--article {
  font-size: 1.5rem;
  color: #828282;
  text-align: center;
  margin-bottom: 35px;

  @media $small-mobile {
    margin-bottom: 15px;
  }
}


.product-card--content {
  display: flex;
  align-items: flex-start;
  color: #000;

  @media $small-handheld {
    flex-direction: column;
  }

  @media $not-small-mobile {
    margin-bottom: 20px;
  }
}

.product-card--discontinued{
  text-align: center;
}
.product-card--art-remain{
  a{
    text-decoration none;
    color #338cd6;
  }

  @media $small-mobile {
    width: 100%;
    height: 45px;
    padding-top: 5px;
    margin-top: 22px;
    border: 1px solid #97CDFA;
    border-radius: 25px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;

    a {
      color: #000;
    }
  }
}

.art-wrap.discontinued-good{
  .product-card--article{
    margin-bottom 0;
  }

}
.product-card--gallery-column {
  width: 38%;
  flex-shrink: 0;

  @media $tablet {
    width: 40vw;
  }

  @media $small-handheld {
    width: calc(100vw - 100px);
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }

  @media $small-mobile {
    width: 100vw;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.product-card--info-column {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media $not-small-handheld {
    margin-left: 40px;
  }

  @media $small-handheld {
    margin-top: 30px;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }

  @media $small-mobile {
    //text-align: center;
    margin-top: 10px;

    .product-card--primary-info-group{
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .product-card--short-params {
    margin: 0 20px;
  }
}

@media $desktop {
  .product-card--primary-info-group {
    width: calc(100% - 180px - 40px);
  }
}

.product-card--collection-group {
  margin-left: 40px;
  width: 180px;

  @media $handheld {
    display: none;
  }
}

.product-card--params {
  width: 100%;

  @media $small-mobile {
    .mobile-spoiler-header {
      border: none;
    }
  }
}

.product-card--description {
  font-size 1.6rem;
}

.product-card--row {
  display: flex;

  @media $not-small-mobile {
    align-items: flex-start;

    .product-card--group + .product-card--group {
      margin-left: 30px;
    }

    .product-card--group {
      flex: 1;
    }
  }

  @media $small-mobile {
    flex-direction: column;
    margin: 12px 0 15px;

    .product-card--group-bordered {
      border: 1px solid #DBE0E8;
      border-right: none;
      flex-grow: 1;
      flex-basis: 0;

      &.add-right-border {
        border-right: 1px solid #DBE0E8;
      }
    }
  }
}


.product-card--main-image {
  position: relative;

  img {
    width: 100%;
  }
}

.product-card--companions {
  position: absolute;
  left: 0; bottom: 0;
  width: 100%;
  padding-top: 10px;
  background: rgba(#333, .9);

  @media $small-mobile {
    padding-top: 7px;
    padding-bottom: 12px;
  }
}

.product-card--companions-description-title {
  font-weight: bold;
  font-size: 1.6rem;
  display: inline;
}

.product-card--companions-caption,
.product-card--companions-slider-container,
.product-card--gallery-caption,
.product-card--gallery-slider-container {
  padding: 0 50px;
}

.product-card--companions-caption,
.product-card--gallery-caption {
  font-size: 1.4rem;
  margin-bottom: 12px;
}

.product-card--companions-caption {
  color: $white;

  @media $small-mobile {
    text-align: center;
    margin-bottom: 5px;
  }
}

.product-card--companions-slider-container,
.product-card--gallery-slider-container {
  position: relative;
  //line-height: 0;

  .slick-arrow {
    @extend .btn;
    width: 30px; height: @width;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid #bdbdbd;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;

    &:active:not(.slick-disabled) {
      margin-top: -2px;
    }

    &:before {
      content: '';
      display: block;
      width: 8px; height: 8px;
      box-sizing: border-box;
      border-top: 2px solid #bdbdbd;
      position: absolute;
      top: 50%;
      margin-top: -1px;
    }
  }

  .slick-disabled {
    opacity: .5;
    cursor: default;
  }

  .slick-prev {
    left: 10px;

    &:before {
      left: 50%;
      border-left: 2px solid #bdbdbd;
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  .slick-next {
    right: 10px;

    &:before {
      right: 50%;
      border-right: 2px solid #bdbdbd;
      transform: rotate(45deg) translateY(-50%);
    }
  }
}

.product-card--companions-slider,
.product-card--gallery-slider {
  overflow: hidden;

  &:not(.slick-initialized) {
    display: flex;
  }

  .item {
    position: relative;

    &:not(.is-active) {
      cursor: pointer;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &__note {
      text-align: center;
      min-width: 55px;
      margin-top: 10px;
      color: white;
      font-size: 1.4rem;
      word-wrap: break-word;
    }

    img {
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
      object-fit: cover;
    }
  }

  .item.is-active:before {
    position: relative;
    z-index: 2;
    box-shadow: inset 0 0 0 4px $white;
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-slide {
    margin-left: 14px;

    @media $small-mobile {
      margin-left: 9px;
    }
  }

  .slick-list {
    margin-left: -14px;

    @media $small-mobile {
      margin-left: -9px;
    }
  }
}

.product-card--companions-slider {
  .item {
    &:after {
      content: '';
      display: block;
      padding-bottom: 13%;

      @media $handheld {
        padding-bottom: 0;
      }

    }

    hr {
      width: 50%;
      margin: 3px auto;
      border-top: 1px solid #fff;
    }

    img {
      width: 100%;
      aspect-ratio: 1;
      height: auto;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
}

.product-card--gallery-slider {
  .product-card--gallery-slide-color {
    text-decoration none;
    font-size: 1.4rem;

    &:before {
      padding-bottom: 0;
    }

    img {
      position relative; //height auto;
    }

    hr {
      width: 50%;
      margin: 3px auto;
    }

    &__img-wrap {
      aspect-ratio: 1 / 1;
      overflow: hidden;
    }

    &__note {
      text-align center;
      min-width 55px;
      margin-top 10px;
      word-wrap break-word;

      &:after {
        content: '';
        display: block;
        padding-bottom: 30%;
      }
    }
  }
}

.product-card--gallery {
  @media $not-small-mobile {
    margin-top: 20px;
  }

  @media $small-mobile {
    margin-top: 10px;
  }
}

.product-card {
  .sharing-block {
    margin-top: 30px;

    @media $small-mobile {
      a:before {
        width: 30px;
        height: 30px;
      }

      margin-top: 15px;
      font-size: 1.8rem;
      width: 95%;
      height: 54px;
      justify-content: space-between;
    }
  }

  .sharing-block-wrapper {
    text-align: center;
  }
}

.sharing-block  {
  display: inline-flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 2px solid $blue;
  line-height: 35px;
  padding: 0 25px;
  border-radius: 30px;
  font-size: 1.5rem;
  position: relative;
  box-sizing: border-box; //left: 50%; transform: translateX(-50%);

  .social-logo {
    width: 20px;
    height: 20px;
  }

  .button--copy-link {
    display: inline-flex;
    width: auto;
    height auto;
    border: 0; //padding: 7px; vertical-align: middle;
  }

  .ya-share2 {
    display: inline-block;
    vertical-align: middle;
  }

  .yazen-image {
    width: 20px;
    margin-left: 15px;
    vertical-align: middle;
  }

  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle; //margin-left: 15px;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;

    &:before {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin: 0;
      //margin-top: 8px;
      //margin-bottom: -8px;
    }
  }
}

.catalogue-page--sharing {
  display: inline-flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: 2px solid $blue;
  line-height: 35px;
  padding: 0 25px;
  border-radius: 30px;
  font-size: 1.5rem;
  position: relative;

  .button--copy-link {
    display: inline-flex;
    width: 32px;
    height 32px;
    padding: 7px;
    border: 0;
    vertical-align: middle;
  }

  @media $small-mobile {
    margin: auto;
  }

  &-wrapper {
    @media $small-mobile {
      width: 100%;
      display: flex;
    }
  }

  li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;

    &:before {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  .ya-share2 {
    display: inline-block;
    vertical-align: middle;
  }

  .yazen-image {
    width: 20px;
    margin-left: 15px;
    vertical-align: middle;
  }
}

.ya-share2__item_service_facebook a:before {
  content: url(/i/sm-fb-mini.svg);
}

.ya-share2__item_service_vkontakte a:before {
  @extend .social-logo-vk;
  content: "";
  display: inline-block;
}

.ya-share2__item_service_odnoklassniki a:before {
  content: url(/i/sm-ok-mini.svg);
}

.ya-share2__item_service_twitter a:before {
  content: url(/i/sm-twit-mini.svg);
}

.ya-share2__item_service_telegram a:before {
  //content: url(/i/sm-tg-mini.svg);
  @extend .social-logo-tg;
  content: "";
  display: inline-block;
}

.ya-share2__item_service_viber a:before {
  @extend .social-logo-viber;
  //content: url(/i/sm-vb-mini.svg);
  content: "";
  display: inline-block;
}

.ya-share2__item_service_whatsapp a:before {
  @extend .social-logo-whatsapp;
  //content: url(/i/sm-wa-mini.svg);
  content: "";
  display: inline-block;
}

.product-card--avail {
  display: flex;
  align-items: center;
  font-size: 16px;

  @media $small-mobile {
    font-size: 1.6rem;
  }

  &.tel {
    &::before {
      content: '';
      display: block;
      width: 27px;
      height: 27px;
      margin: 2px 14px 0 5px;
      float: left;
      background: url(/i/tel.svg) no-repeat;
      background-size: contain;
    }
  }

  &.sale {
    &::before {
      content: '';
      display: block;
      width: 40px;
      height: 30px;
      margin: 2px 5px 0 0;
      float: left;
      background: url(/i/discount-remains.svg) no-repeat;
      background-size: contain;
    }
  }
}

.product-card--delivery-time-and-remains .product-card--avail--svg-wrapper {
  &.time {
    padding-right: 15px;
  }

  &.instore {
    padding-right: 11px;
  }
}

.product-card--terms {
  display: flex;
  font-size: 16px;

  span:first-letter {
    text-transform: capitalize;
  }

  @media $not-small-mobile {
    align-items: center;
    width: max-content;
    text-wrap: nowrap;
    line-height: 25px;
  }

  @media $small-mobile {
    font-size: 1.6rem;
    align-content: center;
  }
}
.product-card--terms.expecting-receipt{
  width 100%;
}
.sale-amount {
  font-weight: 900;
}

.product-card--old-price {
  font-size: 1.8rem;
  color: #828282;
  text-decoration: line-through;

  /* @media $not-small-mobile {
    margin-bottom: 10px;
  } */

  @media $small-mobile {
    text-decoration: none;
  }
}

.product-card--price {
  font-size: 4.5rem;
  color: #000;
  margin-bottom: 25px;

  @media $not-small-mobile {
    margin-top: 20px;
  }

  span {
    font-size: .65em;
  }

  @media $tablet {
    font-size: 3.6rem;
  }

  @media $small-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 15px 0 0;
    text-align: center;

    span {
      font-size: 20px;
    }
  }
}

.product-card--discount {
  display: inline-block;
  vertical-align: middle;
  width: 47px; height: @width;
  line-height: @height;
  text-align: center;
  border-radius: 50%;
  background: $blue;
  color: $white;
  font-size: 1.4rem;
  margin-left: 10px;
  margin-top: -10px;

  @media $tablet {
    width: 40px; height: @width;
    line-height: @height;
  }
}

.product-card--short-params {
  width: auto;
  font-size: 1.4rem;

  td {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  td + td {
    padding-left: 15px;
  }

  @media $small-mobile {
    width: 80vw !important;
    margin: auto;
    margin-bottom: 10px;
    font-size: 1.6rem;

    td {
      display: inline-block;
      width: 60vw !important;
      padding-top: 3px;
      padding-bottom: 3px;
      white-space: nowrap;
      overflow: hidden;

      &:after {
        content:' ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ ˍ';
        color: #DEDEDE;
        padding-left: 5px;
        font-size: 12px;
      }
    }

    td + td {
      width: 20vw !important;
      padding-left: 0;
      float: right;

      &:after {
        content: none;
      }
    }
  }
}


@media $small-mobile {
  .product-card--group_avail-group {
    order: 2;
  }

  .product-card--group_params-group {
    display: flex;
    flex-direction: column;
    order: 1;
  }
}

.product-card--not-creating {
  width: 100%;
  height: 52px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #FFEDEF;
  text-align: left;
  font-size: 1.6rem;
  line-height: 52px;

  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 34px;
    margin: 8px 10px 0 20px;
    float: left;
    background: url(/i/alert.svg) no-repeat;
    background-size: contain;
  }
}

.product-card--delivery-time-and-remains {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid #97CDFA;
  padding: 10px 20px;

  @media $not-small-mobile {
    .uppercase {
      text-transform: none !important;
    }
  }

  @media $small-mobile {
    gap: 10px;
    margin-top: 12px;
  }
}

.product-card--row_buy-block {
  display: flex;
  align-items: flex-start;

  @media $not-small-mobile {
    margin-bottom: 25px;
  }

  @media $small-mobile {
    justify-content: space-evenly;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 0 20px 0;
  }
}

.product-card--buttons-group {
  position: relative;

  @media $small-mobile {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    //max-width: 250px;
    //width: 100%;
  }
}

.product-card--buy-btn {
  @extend .btn, .blue-btn, .rounded-btn;
  box-sizing: border-box;
  font-size: 2.2rem;
  line-height: 44px;
  padding-left: 40px;
  text-align: center;
  width: 150px;
  box-shadow: 0 4px 20px rgba(#000, .25);
  position: relative;
  white-space: nowrap;

  &.active {
    font-size: 1.8rem;
  }

  &:before {
    content: '';
    background: url(/i/cart-icon.svg) no-repeat 50%;
    width: width('i/cart-icon.svg'); height: height('i/cart-icon.svg');
    position: absolute;
    left: 18px; top: 50%;
    transform: translateY(-50%);
  }

  @media $small-mobile {
    box-shadow: none;
    width: 195px;
    border-radius: 10px;
    padding-left: 30px;
    font-size: 24px;

    &:before {
      left: 35px;
    }
  }
}

.product-card--fast-order-link {
  display: inline-block;
  margin-top: 20px;
  font-size: 1.4rem;
  color: #828282;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(#828282, .5);
}

.product-card--fav-btn-inline {
  @extend .btn;
  width: 47px;
  height: 47px;
  line-height: 47px;
  margin-top: 8px;
  margin-left: 4px;
  background: url(/i/heart-icon-contour.svg) no-repeat 50% / 32px auto;
  font-size: 0;
  position: absolute;
  transition: opacity .25s ease;

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    margin-top: 0;
  }
  @media only screen and (max-width: 480px){
    position: static;
    margin: 0;
  }
}

.product-card--fav-btn {
  @extend .btn;
  width: 32px; height: 32px;
  background: url(/i/heart-icon-contour.svg) no-repeat 50% / 32px auto;
  font-size: 0;
  position: absolute;
  top: -50px;
  right: -15px;
  transform: translateY(-50%);
  transition: opacity .25s ease;

  @media only screen and (max-width: 480px){
    top: 28%;
    right: -44px;
  }
}

.product-card--fav-btn.active,
.product-card--fav-btn-inline.active {
  background: url(/i/heart-icon.svg) no-repeat 50% / 32px auto;
}

.product-card--spinner {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 2px;

  @media $small-mobile {
    display: flex;
    margin: 0;
    //margin-top: 20px;
    //margin-bottom: 30px;
    //margin-left: auto;
    //margin-right: auto;
  }
}

.product-card--calculator-link {
  @extend .btn, .gray-bordered-btn, .rounded-btn;
  font-size: 1.4rem;
  line-height: 44px;
  padding: 0 28px;
  white-space: nowrap;

  @media $tablet {
    line-height: 33px;
    height: 37px;
    font-size: 1.3rem;
    padding: 0 15px;
  }

  @media $small-mobile {
    width: 100%;
    max-width: 250px;
    box-sizing: border-box;
  }
}

.product-card--colors-popup-link {
  background: #97cdfa;
  border-radius: 30px;
  text-decoration: none;
  max-width: 200px;
  width: 100%;
  display: flex;
  font-size: 1.4rem;
  line-height: 44px;
  padding: 0 28px;
  white-space: nowrap;
  box-sizing: border-box;
  justify-content: center;
  margin-bottom: 20px;

  @media $small-mobile {
    max-width: 250px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.product-card--found-cheaper {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 1.4rem;

  @media $small-mobile {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  a {
    @extend .btn, .blue-btn;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 15px;
    font-size: 1.4rem;
  }
}

.product-card__colors-variants {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px 65px;
  margin-bottom: 25px;

  @media $small-mobile {
    grid-template-columns: 1fr;
  }
}

.product-card__colors-variants__caption {
  margin-bottom: 10px;
}

.product-card__colors-variants__list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product-card__colors-variants__list.product-card__colors-variants__list--round-items {
  .product-card__colors-variants__list-item {
    width: 33px;
    height: @width;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: unset;
  }

  .radio {
    padding: unset;
    width: 100%;
    height: 100%;
  }

  .radio:before {
    width: 100%;
    height: @width;
    border: 0.5px solid #bdbdbd;
  }
}

.product-card__colors-variants__list-item {
  display: flex;

  .radio {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radio:before {
    background: transparent;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }

  &:hover .radio:before {
    background: rgba(151, 205, 250, 0.7);
  }

  &.is-checked .radio:before {
    background: $blue;
    border: 0;
  }

  &:nth-child(5) ~ & {
    opacity: 0;
    height: 0;
    width: 0;
    transition: all 0.1s ease;
    transform: scaleY(0);
    transform-origin: 0 0;
    position: absolute;
  }
}

.product-card__colors-variants__list.is-expanded .product-card__colors-variants__list-item:nth-child(5) ~ .product-card__colors-variants__list-item {
  opacity: 1;
  height: auto;
  width: 33px;
  transition: all 0.1s ease;
  transform: scaleY(1);
  transform-origin: 0 0;
  position: unset;
}

.product-card__colors-variants-show-more {
  width: 33px;
  height: @width;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #d9dfe7;
  border-radius: 50%;
  font-size: 13px;
  color: #828282;
}

.product-card--delivery-caption,
.product-card--payment-caption {
  display: inline-block;
  font-size: 1.8rem;
  line-height: 1.5;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 15px;
}

.product-card--delivery-content,
.product-card--payment-content {
  font-size: 1.4rem;
  line-height: 1.5;
}

.product-card--delivery-group + .product-card--delivery-group {
  margin-top: 15px;
}

@media $small-mobile {
  .product-card--row_delivery-and-payment {
    position: relative;
    padding-top: 0;
    flex-direction: row;
    margin-top: 20px;
  }

  .product-card--delivery-caption,
  .product-card--payment-caption {
    box-sizing: border-box;
    //width: calc(50% + 1px);
    //bordered-btn(2px, #d9dfe7);
    line-height: 41px;
    font-size: 1.6rem;
    color: #000;
    border: none;
    //position: absolute;
    //top: 0;
    position: static;
    margin: 0;

    .is-active & {
      background: #d9dfe7;
    }
  }

  .product-card--delivery-caption {
    left: 0;
  }

  .product-card--payment-caption {
    right: 0;
  }

  .product-card--delivery-content,
  .product-card--payment-content {
    display: none;

    .is-active & {
      display: block;
    }
  }
}

.product-card--colors-description-title {
  font-weight: bold;
  font-size: 1.6rem;
  display: inline;
}

.product-card--delivery-subcaption {
  // color: #828282;
  font-weight: bold;
  font-size: 1.6rem;
}

.product-card--payment-variants {
  li {
    list-style: none;
    padding-left: 25px;
    position: relative;
    background: url(/i/mark-icon-dark.svg) no-repeat 0 2px;
  }

  li + li {
    margin-top: 15px;
  }

  li span {
    display: block;
    color: #828282;
  }
}


.product-card--producer-caption {
  font-size: 1.5rem;
  line-height: 22px;
  margin-bottom: 25px;
}

.product-card--producer + .product-card--collection {
  margin-top: 30px;
}

.product-card--collection-caption {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.product-card--collection-name {
  text-align: center;
  font-size: 1.8rem;
}

.product-card--collection-amount {
  text-align: center;
  font-size: 1.4rem;
}


.product-card--params {
  margin-top: 35px;

  @media $small-mobile {
    margin: 0;
  }
}

.product-card--params-content {
  @media $not-small-mobile {
    display: flex;
    align-items: flex-start;
  }
}

.product-card--params-caption {
  @extend .h2, .caption-prototype;
  margin-bottom: 40px;
}

.product-card--params-list {
  font-size: 1.4rem;

  @media $not-small-mobile {
    width: 50%;
  }

  th, td {
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
  }

  th {
    font-weight: normal;
    color: $dark-gray;
    text-align: left;
  }

  td {
    padding-left: 20px;
    color: #000;
  }

  @media $small-mobile {
    text-align: left;

    th {
      width: 30%;
    }
  }
}


.other-section {
  @media $not-small-handheld {
    margin-bottom: 50px;
  }

  @media $portrait-tablet {
    margin-bottom: 35px;
  }
}

.other-section--caption {
  @extend .h2, .caption-prototype;

  @media $not-mobile {
    margin-bottom: 40px;
  }

  @media $mobile {
    margin-bottom: 15px;
  }

  @media $small-mobile {
    &.mobile-spoiler-header {
      border: none;
    }
  }
}

.other-section--tags {
  font-size: 1.6rem;
  text-align: center;

  @media $not-mobile {
    margin-top: -20px;
    margin-bottom: 40px;
  }

  @media $mobile {
    margin-top: -5px;
    margin-bottom: 15px;
  }

  &, a {
    color: #828282;
  }
}


.questions-section {
  background: #d9dfe7 url(/i/questions-bg.jpg) no-repeat 0 50%;

  @media $not-small-handheld {
    margin-bottom: 50px;
  }

  @media $notebook {
    background-position: -20% 50%;
  }

  @media $landscape-tablet {
    background-position: -80% 50%;
  }

  @media $small-handheld {
    background: #d9dfe7;
    text-align: center;

    @media $not-small-mobile {
      margin-bottom: 35px;
    }
  }
}

.questions-section--content {
  @media $not-small-handheld {
    height: 385px;
    display: flex;
    align-items: center;
    padding-left: 50%;
  }

  @media $small-handheld {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.questions-section--caption {
  @media $not-small-handheld {
    font-size: 3.8rem;
    margin-bottom: 25px;
  }

  @media $small-handheld {
    font-size: 2.4rem;
    margin-bottom: 3px;
  }
}

.questions-section--text {
  @media $not-small-handheld {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  @media $small-handheld {
    font-size: 1.4rem;
  }

  p + p {
    margin-top: 15px;
  }
}

.questions-section--phone {
  color: #000;

  @media $not-small-handheld {
    font-size: 3.6rem;
  }

  @media $small-handheld {
    font-size: 1.8rem;
  }
}

.questions-section--phone + .questions-section--phone {
  @media $not-small-handheld {
    margin-top: 0;
  }

  @media $small-handheld {
    margin-top: 7px;
  }
}

@media $not-small-mobile {
  .responses-section {
    margin-bottom: 50px;
  }
}

.responses-section--caption {
  @extend .h2, .caption-prototype;

  @media $not-mobile {
    margin-bottom: 60px;
  }

  @media $mobile {
    margin-bottom: 25px;
  }
}

.product-card--responses-hint {
  margin-bottom: 1em;
}


.vitrine-slider-container {
  padding: 0 40px;
  position: relative;

  @media $notebook {
    max-width: calc(100vw - 140px);
  }

  @media $handheld {
    max-width: calc(100vw - 120px);
  }

  @media $small-mobile {
    max-width: calc(100vw - 100px);
  }

  .slick-arrow {
    @extend .btn;
    width: 30px; height: @width;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid #bdbdbd;
    background: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -10px;
    font-size: 0;

    &:active:not(.slick-disabled) {
      margin-top: -8px;
    }

    &:before {
      content: '';
      display: block;
      width: 8px; height: 8px;
      box-sizing: border-box;
      border-top: 2px solid #bdbdbd;
      position: absolute;
      top: 50%;
      margin-top: -1px;
    }
  }

  .slick-disabled {
    opacity: .5;
    cursor: default;
  }

  .slick-prev {
    left: 0;

    &:before {
      left: 50%;
      border-left: 2px solid #bdbdbd;
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  .slick-next {
    right: 0;

    &:before {
      right: 50%;
      border-right: 2px solid #bdbdbd;
      transform: rotate(45deg) translateY(-50%);
    }
  }
}

.product-card--get-discount {
  display: flex;
  flex-direction: row;
  border: 1px solid #F97383;
  border-radius: 50px;
  font-size: 16px;
  align-items: center;
  padding: 5px 22px;
  min-width: 0;
  line-height: 18px;

  span {
    text-align: center;
  }
}

.product-card--catalog-link {
  border: 1px solid #97CDFA;
  border-radius: 50px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  padding: 5px 10px;
  text-align: center;
  line-height: 18px;
  text-decoration: none;

  &.no-flex {
    display: block;
    height: 46px;
    padding: 0;
    line-height: 46px;
  }
}

.product-card--catalog-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;

  .product-card--catalog-link-mobile {
    height: 45px;
    padding-top: 5px;
    border-radius: 50px;
    border: 1px solid #97CDFA;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    @media $small-mobile {
        margin: 20px 0;
      }
    &.stock {
      width: calc(57% - 5px);
      
    }

    &.all {
      width: calc(43% - 5px);
    }
  }
}

.archive {
  text-align: center;
  color: #338cd6;

  @media $small-mobile {
    h3.not-creating {
      display: flex;
      align-items: center;
      width: 100%;
      height: 62px;
      margin-top: 10px;
      border-radius: 10px;
      background-color: #FFEDEF;
      text-align: left;
      font-size: 1.6rem;
      font-weight: normal;
      color: #000;
  
      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 40px;
        margin: 0 10px 0 20px;
        float: left;
        background: url(/i/alert-high.svg) no-repeat;
        background-size: contain;
      }
    }
  }
}

.vitrine-slider {
  overflow: hidden;

  .product {
    display: block;
    text-decoration: none;
  }

  .product--image {
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      width: 100%; height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0; top: 0;
    }
  }

  .product--date-seen {
    padding-left: 24px;
    background: url(/i/eye-icon.svg) no-repeat 0 50%;
    font-size: 1.4rem;
    color: #828282;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
  }

  .product--name {
    font-size: 1.4rem;
    text-align: center;
    text-decoration: underline;
  }

  .slick-slide {
    margin-left: 18px;
  }

  .slick-list {
    margin-left: -18px;
  }
}


.companion-popup {
  display: none;
  box-sizing: border-box;
  width: 88%; min-height: 100%;
  padding-bottom: 30px;
  background: $dark-gray;
  position: absolute;
  left: 100%; top: 0;
  z-index: 10;

  @media $small-handheld {
    left: 10%;
    width: 80%;
  }

  @media $small-mobile {
    padding-top: 60px;
  }

  &--remains-block {
    text-align center;
    color #fff;
    margin-top 20px;
  }
}

.companion-popup--image {
  background-position: 50%;
  background-size: cover;
  margin-bottom: 35px;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @media $small-mobile {
    margin-bottom: 30px;
  }
}

.companion-popup--buy-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;
  width: 90%;
  left: 5%;

  @media $small-mobile {
    justify-content: center;
  }
}

.companion-popup--article {
  color: #bdbdbd;
  font-size: 1.5rem;
  margin-top: 5px;
  width: 33.33%;
}

.companion-popup--price {
  color: $white;
  font-size: 2.4rem;
  width: 33.33%;

  @media $small-mobile {
    margin-left: 20px;
  }
}

.companion-popup--buy-button {
  @extend .btn, .blue-btn, .rounded-btn;
  box-sizing: border-box;
  font-size: 1.5rem;
  height: 36px;
  line-height: 33px;
  padding-left: 18px;
  text-align: center;
  width: 105px;
  box-shadow: 0 4px 20px rgba(#000, .25);
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;

  &:before {
    content: '';
    background: url(/i/cart-icon.svg) no-repeat 50% 40% / contain;
    width: 20px; height: 21px;
    position: absolute;
    left: 12px; top: 50%;
    transform: translateY(-50%);
  }

  @media $small-mobile {
    position: absolute;
    left: 50%; top: -65px;
    transform: translateX(-50%);
  }
}

.companion-popup--card-link {
  @extend .btn, .white-bordered-btn, .rounded-btn;
  padding-left: 20px;
  padding-right: 40px;
  line-height: 34px;
  font-size: 1.6rem;
  margin-top: 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: '';
    display: block;
    width: 5px; height: 5px;
    border-right: 2px solid $white;
    border-top: 2px solid $white;
    position: absolute;
    top: 50%; right: 15px;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    transition: border-color .25s ease;
  }

  &:hover:after {
    border-color: $black;
  }
}

.companion-popup--close {
  @extend .btn;
  width: 29px;
  height: 29px;
  background: #d9dfe7;
  border-radius: 50%;
  position: absolute;
  top: 15px; right: 15px;
  transform: rotate(45deg);
  cursor: pointer;

  &:hover {
    background: lighten($red, 10);
  }

  &:before, &:after {
    content: '';
    display: block;
    background: $white;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 15px; height: 1px;
  }

  &:after {
    width: 1px; height: 15px;
  }
}

.param-hint-icon {
  display: inline-block;
  width: 20px; height: @width;
  border-radius: 50%;
  background: $blue;
  vertical-align: middle;
  // margin-top: -20px;
  margin-right: -23px;
  position: relative;
  font-style: normal;

  &:before {
    content: '?';
    display: block;
    font-size: 1.2rem;
    line-height: 20px;
    color: #000;
    text-align: center;
  }

  &:after {
    content: '';
    display: block;
    width: 21px; height: @width;
    border-radius: 3px;
    background: $blue;
    position: absolute;
    left: 50%; bottom: 100%;
    margin-bottom: 5px;
    transform: translateX(-50%) rotate(-45deg);
    pointer-events: none;
    opacity: 0;
    transition: opacity .1s ease;
  }
}

.param-hint {
  display: none;
  // overflow: hidden;
  // opacity: 0;
  // max-height: 0;
  position: absolute;
  // left: -25px; bottom: 100%;
  // margin-bottom: 11px;
  transform: translateY(-100%);
  background: $blue;
  color: #000;
  font-size: 1.1rem;
  transition: all .25s ease;
  width: 270px;
  box-sizing: border-box;
  border-radius: 20px;

  @media $small-mobile {
    left: 18px !important;
  }

  // &:before {
  //   content: '';
  //   display: block;
  //   width: 21px; height: @width;
  //   border-radius: 3px;
  //   background: $blue;
  //   position: absolute;
  //   left: 25px; top: 100%;
  //   transform: translateY(-50%) rotate(-45deg);
  //   margin-top: -3px;
  //   pointer-events: none;
  // }
}

.param-hint__body {
  padding: 10px 20px 25px;
  white-space: normal;

  p + p {
    margin-top: .75em;
  }
}

.param-hint__caption {
  color: $white;
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 15px 0;
  margin-bottom: -15px;
  text-align: center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: $white;
    position: absolute;
    left: 0; top: 50%;
    z-index: -1;
  }

  span {
    position: relative;
    padding-left: 7px;
    padding-right: 7px;
    background: $blue;
  }
}

.param-hint-icon:hover {
  &:after {
    opacity: 1;
    // pointer-events: auto;
  }

  // .param-hint {
  //   opacity: 1;
  //   max-height: 500px;
  // }
}

.del-pay-gua-section {
  margin-bottom: 30px;
  margin-top: 30px;
}

.del-pay-gua-box {
  display: flex;
  flex-direction: column;
  margin-bottom: inherit;

  .del-pay-gua-item {
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 25px;
  }

  @media only screen and (max-width: 700px) {
    .del-pay-gua-item:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-top: 2px solid #4f4f4f;
      border-right: 2px solid #4f4f4f;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: rotate(135deg) translate(-90%,0);
      margin-right: 10px;
    }

    .del-pay-gua-item.changed:after {
      transform: rotate(-45deg) translate(-20%,-60%);
      margin-right: 2px;
    }
  }
}

.del-pay-gua-info-box {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;

  .del-pay-gua-info {
    max-width: 90%;
    min-width: 90%;
    margin-bottom: 15px;
    margin-top: 15px;

    a {
      pointer-events: auto;
      cursor: pointer;
      text-decoration: underline;
    }

    .del-pay-gua_header {
      font-size: 25px;
      font-weight: 600;
    }

    ul {
      margin-left: 50px;
    }

    .mb-15 {
      margin-bottom: 15px;
    }
  }
}

.product-card--flex {
  display: flex;
  flex-direction: column;
}

.show-info-link {
  cursor: pointer;

  @media $small-mobile {
    width: 100%
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

// Блок со статьями

.info-paper {
  margin-bottom 40px;
  display flex;
  align-items center;
  flex-direction: column;

  .info-header {
    margin-bottom 20px;
  }

  .papers-box {
    .papers-list {
      li {
        display block;
        margin-bottom 10px;
        background-color #F1F0F5;
        border-radius 5px;

        .paper-item {
          .paper-label {
            cursor pointer;
            padding 20px 20px 20px 20px;
            display: flex;
            justify-content: space-between;

            .paper-open-button-wrap{
              .paper-open-button {
                width: 10px;
                height: 10px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                position: relative;
              }

              .paper-open-button:before {
                width: 100%;
                height: 2px;
                content: '';
                display: block;
                border-radius: 1px;
                background: #000;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                position: absolute;
              }

              .paper-open-button:after {
                width: 2px;
                height: 100%;
                -webkit-transition: opacity .25s ease;
                transition: opacity .25s ease;
                content: '';
                display: block;
                border-radius: 1px;
                background: #000;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                position: absolute;
              }

              .is-expanded.paper-open-button:after {
                opacity: 0;
              }
            }
          }
          .paper-content {
            display: flex;
            justify-content: space-between;
            padding 20px 20px 20px 20px;
            .paper-img {
              width 35%;
              height: auto;
              img {
                max-width 90%;
                height: auto !important;
              }
            }

            .paper-text-box {
              width 65%;
              display: flex;
              align-items: center;

              .paper-text {
                display: flex;
                flex-direction: column;

                .paper-read-more {
                  margin-top 10px;
                }
              }
            }

            @media only screen and (max-width: 750px) {
              flex-direction: column;
              text-align: center;
              .paper-img {
                width: 100%;
                margin-bottom: 20px;
              }
              .paper-text-box {
                width: 100%;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.info-paper > * {
  display block;
  width 90%;
}

.product-same-picture {
  .product-fav {
    float: right;
    margin-right: 60px;

    .product-card--fav-btn-inline {
      z-index: 1;
      @media $small-mobile {
        top: -25px;
        left: 10px;
      }
    }

    @media $small-mobile {
      margin-right: 10px;
      margin-top: 15px;
    }
  }

  .product {
    .product--name{
      @media $small-mobile {
        word-break: break-word;
      }
    }
  }

  .product--price {
    display: inline-block;
    font-size 1.6rem;
  }

  .old-price-sale {
    display: inline-block;
    font-size: 2.4rem !important;
  }

  .product--price-group {
    display: flex;
    justify-content: space-evenly;
  }

  .product--footer {
    line-height: 52px;
  }

  .sale-info-text {
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.1;
    display: block;

    .avail-count {
      font-size: inherit;
    }
  }

  .sale-amount {
    font-weight: 900;
  }

  @media only screen and (max-width: 700px){
    .sale-group {
      flex-direction: column;
      line-height: 1.1;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 17px;

      .old-price-sale, .product--price {
        font-size: 1.8rem !important;
      }
    }

    .sale-info-text {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
