
/*   -=========== Common components ===========-   */

.currency {
  span {
    display: none;
  }

  &:before {
    content: 'i';
    font-family: 'rouble';
  }
}

.caption-prototype {
  overflow: hidden;
  text-align: center;
  position: relative;
  color: #000;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 55%;
    z-index: -1;
  }

  span {
    background: $white;
    padding-left: 25px;
    padding-right: 25px;

    @media $mobile {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}


.product-icons-prototype {
  .icon {
    display: block;
    width: 35px; height: 35px;
    border-radius: 50%;
    background-color: $blue;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .icon + .icon {
    margin-top: 14px;
  }

  for $item in 'leader' 'new' {
    .icon_{$item} {
      background-image: url('/i/' $item '-icon.svg');
    }
  }

  .icon_leader {
    background-position: 50% 40%;
  }
}


.attention-block {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;

  .product-card & {
    margin-bottom: 35px;
  }

  @media $not-small-mobile {
    .product-card--article + & {
      margin-top: -25px;
    }
  }
}

.attention-block--content {
  display: inline-block;
  padding: 10px 20px 10px 55px;
  font-size: 1.8rem;
  position: relative;
  background: #f2f2f2;

  @media $small-mobile {
    font-size: 1.4rem;
    text-align: left;
  }

  &:before {
    content: '';
    display: block;
    width: 28px; height: @width;
    border-radius: 50%;
    background: $blue url(/i/attention-icon.svg) no-repeat 50%;
    position: absolute;
    left: 15px; top: 50%;
    transform: translateY(-50%);
  }
}

.social-logo {
  cursor: pointer;
}

.social-logo-vk {
  background: url(/i/img/social/vk.png) no-repeat;
  background-size: contain;

  @media $small-mobile {
    background: url(/i/img/social/vk.svg) no-repeat;
    background-size: contain;
  }
}

.social-logo-tg {
  background: url(/i/img/social/telegram.png) no-repeat;
  background-size: contain;

  @media $small-mobile {
    background: url(/i/img/social/telegram.svg) no-repeat;
    background-size: contain;
  }
}

.social-logo-viber {
  background: url(/i/img/social/viber.png) no-repeat;
  background-size: contain;

  @media $small-mobile {
    background: url(/i/img/social/viber.svg) no-repeat;
    background-size: contain;
  }
}

.social-logo-whatsapp {
  background: url(/i/img/social/whatsapp.png) no-repeat;
  background-size: contain;

  @media $small-mobile {
    background: url(/i/img/social/whatsapp.svg) no-repeat;
    background-size: contain;
  }
}

.dis-none {
  display: none !important;
}