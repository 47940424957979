
/*   -=========== Buttons ===========-   */

.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all .25s ease;
}

// use box-shadow instead of border to preserve button line-height
bordered-btn($border, $color) {
  box-shadow: inset 0 0 0 $border $color;
}

.black-bordered-btn {
  color: $black;
  bordered-btn(1px, $black);

  &:hover {
    color: $black;
  }
}

.black-btn {
  background-color: $black;
  color: $white;

  &:hover {
    color: $white;
    background-color: lighten($black, 10);
  }
}

.blue-btn {
  background-color: $blue;
  color: $white;

  &:hover {
    background-color: $dark-blue;
    color: $white;
  }
}

.gray-bordered-btn {
  color: #333146;
  bordered-btn(2px, #bdbdbd);

  &:hover {
    color: #333146;
    background-color: #bdbdbd;
  }
}

.white-bordered-btn {
  color: $white;
  bordered-btn(1px, $white);

  &:hover {
    background-color: $white;
    color: $black;
  }
}

.rounded-btn {
  border-radius: 30px;
}

.button {
  &--loading {
    svg {
      display none;
    }

    .button__loader {
      display block;
    }
  }

  &__loader {
    display none;
    width: 40px;
    height: 40px;

    svg {
      width 100%;
      height 100%;
    }
  }

  &__icon {
    width: 18px;
    height 18px;

    svg {
      width 100%;
      height 100%;
    }
  }

  &--copy-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #97cdfa;
    height: 40px;
    width 40px;
    border: 1px solid #97cdfa;
    background: transparent;
    cursor: pointer;
    border-radius: 8px;

    * > {
      pointer-events: none;
    }
  }
}

// Sizes

.wide-btn {
  width: 100%;
}

.h35btn {
  line-height: 32px;
  height: 35px;
}

.h40btn {
  line-height: 37px;
  height: 40px;
}

.h44btn {
  line-height: 40px;
  height: 44px;
}

.ias-trigger-prev {

}
