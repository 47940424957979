
/*   -=========== Filter ===========-   */

.filter {
  box-sizing: border-box;
  padding: 10px 20px 40px 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }

  .checkbox,
  .radio {
    font-size: 1.4rem;

    @media $handheld {
      font-size: 16px;
    }
  }

  @media $handheld {
    padding-left: 10px;
  }

  @media $desktop {
    &:before {
      content: '';
      display: block;
      width: 50vw; height: 100%;
      position: absolute;
      right: 0; top: 0;
      z-index: -1;
      box-shadow: 5px 5px 10px rgba(#000, .1);
    }
  }
}

.filter--group_static {
  padding-bottom: 10px;
}

.filter--group-caption {
  text-transform: uppercase;
  font-size: 1.6rem;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.filter--group_collapsible {
  .filter--group-caption {
    padding-right: 35px;
    cursor: pointer;
    .additional-filter-label {
      margin-bottom: 30px;
    }
    .remains-filter-label {
      color: #000000;
    }
  }

  @media $handheld {
    border-top: 1px solid #c3e4ff;
  }
}

.filter--group_collapsible + .filter--group_collapsible {
  border-top: 1px solid #bdbdbd;

  @media $handheld {
    border-color: #c3e4ff;
  }
}

.filter--group_unboarded + .filter--group_collapsible {
  border-top: none;
}

.filter--group_tone {
  display none;
}

.is-expanded + .filter--group_tone {
  display: block;
}

.filter--group-body {
  font-size: 1.4rem;
  overflow: hidden;
  transition: all .25s ease;

  .selected-item {
    padding-right: 25px;
    position: relative;
  }

  @media $handheld {
    .selected-item {
      padding: 5px 40px 5px 5px;
      background-color: #deefff;
      font-size: 16px;

      &:first-child {
        margin-top: .5em;
      }
    }

    .selected-item + .selected-item {
      margin-top: 6px;
    }
  }

  .selected-item--remove {
    @extend .btn;
    width: 20px; height: 20px;
    font-size: 0;
    position: absolute;
    top: 0; right: 0;
    margin-top: -2px;
    transform: rotate(-45deg);

    &:before, &:after {
      content: '';
      display: block;
      background: $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 2px; height: 10px;
    }

    &:after {
      width: 10px; height: 2px;
    }

    @media $handheld {
      width: 30px; height: 30px;
      margin-top: auto;

      &:before {
        height: 20px; width: 1px;
      }

      &:after {
        width: 20px; height: 1px;
      }
    }
  }
}

.filter--group-arrow {
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  background: url(/i/arrow-right.svg) no-repeat;
  background-size: contain;
  transition: .25s;
  transform-origin: 5px 5px;
}

.filter--group-toggler {
  width: 10px; height: 10px;
  position: absolute;
  right: 14px; top: 50%;
  transform: translateY(-50%);

  &:before, &:after {
    content: '';
    display: block;
    border-radius: 1px;
    background: #000;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 100%; height: 2px;
  }

  &:after {
    width: 2px; height: 100%;
    transition: opacity .25s ease;
  }
}

.filter--group-toggler.sale-filter-toggle {
  top: 77%;
}

.filter--group_collapsible:not(.is-expanded) {
  .filter--group-body {
    max-height: 0;
    opacity: 0;
  }
}

.filter--group_collapsible.is-expanded {
  .filter--group-body {
    max-height: 5000px;
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .filter--group-toggler:after {
    opacity: 0;
  }

  .filter--group-arrow {
    transform: rotate(90deg);
  }
}

.filter--links-group {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .filter-element-anchor--item {
    cursor: pointer;
  }

  .item--amount {
    padding-top: .15em;
    color: #828282;
    margin-left: 10px;
  }

  .item + .item {
    margin-top: .75em;
  }

  .item-link {
    text-decoration: none;
    font-size: 1.4rem;
  }

  .item-link + .item-link {
    margin-inline: .75em;
  }

  a {
    color: #4f4f4f;
  }

  a.radio, a.checkbox,
  .radio a, .checkbox a {
    text-decoration: none;
  }
}

.filter--radio-group + .filter--links-group {
  margin-top: 2em;
}

.filter--radio-group,
.filter--checkbox-group {
  .item + .item {
    margin-top: 8px;
  }
}

.filter--search {
  margin-bottom: 10px;

  .inp {
    font-size: 1.3rem;
    padding-top: .85em;
    padding-bottom: .85em;
  }
}

.filter--toggle-list-btn {
  @extend .btn, .blue-btn, .rounded-btn, .h40btn;
  padding: 0 52px 0 25px;
  font-size: 1.5rem;
  position: relative;
  margin-top: 30px;
  display: none;

  span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -8px;
    white-space: nowrap;
  }

  &:after {
    content: '';
    display: block;
    background: url(/i/right-arrow-icon.svg) no-repeat 50%;
    width: width('i/right-arrow-icon.svg'); height: height('i/right-arrow-icon.svg');
    position: absolute;
    right: 20px; top: 50%;
    transform: translateY(-50%);
  }

  .filter--group-body.is-expanded & {
    padding-right: 25px;

    &:after,
    .collapsed-text {
      display: none;
    }
  }

  .filter--group-body:not(.is-expanded) & {
    .expanded-text {
      display: none;
    }
  }
}

.filter--group-body:not(.is-expanded) {
  .item:not(.is-hidden):nth-of-type(15) ~ .item {
    display: none;
  }
}

.item:not(.is-hidden):nth-child(11) ~ .filter--toggle-list-btn {
  display: inline-block;
}

.is-filtered .item ~ .filter--toggle-list-btn {
  display: none !important;
}

.filter--group-body .item.is-hidden {
  display: none;
}

.filter--colors-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .item {
    margin: 18px 16px 0 0;
    position: relative;
  }

  .check-mark {
    position: absolute;
    top: 9px;
    left: 8px;
    z-index: 1;
    display: none;
    pointer-events: none;
  }

  .color-box {
    box-sizing: border-box;
    display: block;
    width: 30px; height: @width;
    border-radius: 50%;
    transition: opacity .25s ease;
    cursor: pointer;
    position: relative;

    &:not(.is-checked):hover {
      opacity: .7;
    }

    &.is-checked {
      //border: 2px solid $gray !important;

      + .check-mark {
        display: initial;
      }
    }

    input {
      display: none;
    }
  }

  .color-labeled.is-checked {
    + .check-mark {
      display: initial;
      top: 11px;
      left: 10px;
    }
  }

  .item:has(.color-labeled) {
    margin-right 10px;
  }

  .item:has(.color-labeled):last-child {
    margin-right 0;
  }

  .color-labeled {
    display flex;
    text-decoration: none;
    flex-direction: row;
    align-items: center;

    .color-box {
      margin-right: 5px;
      width: 35px;
      height: @width;
    }
  }
}

.filter--reset-button {
  @extend .btn, .blue-btn, .rounded-btn;
  line-height: 26px;
  padding: 0 20px;
  margin-top: 20px;
  font-size: 1.4rem;

  @media $handheld {
    width: calc(100% - 42px); // padding 20 + 20, border 1 + 1
    padding: 5px 20px;
    margin-top: 12px;
    border: 1px solid #909090;
    background-color: transparent;
    line-height: 21px;
    font-size: 14px;
    text-align: right;
    color: #757575;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      float: left;
      background: url(/i/trash.svg) no-repeat;
      background-size: contain;
    }
  }
}

.countSlideContainer {
  width: 100%;
  .countSlideContainer-input {
    margin-bottom 20px;
  }
  .countSlideContainer-value {
    text-align: center;
    font-size: 1.8rem;
    .countInput-box {
      max-width 50px;
      border: #C0C0C0 solid 1px;
      border-radius: 5px;
      margin-left: 5px;
      margin-right: 5px;
      .countInput {
        max-width: inherit;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
}

.countSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.countSlider:hover {
  opacity: 1;
}

.countSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

.countSlider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

.filter-refresh {
  display none;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 248px;
  z-index: 10001;

  @media $small-mobile {
    width: min-content;
  }

  &__link-box {
    color white;
    text-decoration none;
    display flex;

    &:hover {
      color white;

      .filter-refresh__arrow:before {
        background #696969;
      }

      .filter-refresh__loading:after {
        content: url('/i/img/filter-loading-hover.gif');
      }
    }
  }

  &__arrow {
    &:before {
      content: '';
      display: block;
      background: $black;
      width: 10px;
      height: 10px;
      position: relative;
      top: 40%;
      transform: rotate(45deg);
      margin-right: -5px;
      transition: 0.25s;
    }
  }

  &__button {
    background: $black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: 0.25s;
    color white;
    text-decoration none;
    display flex;

    &:hover {
      color white;
      background #696969;
    }

    @media $small-mobile {
      font-size 1.6rem;
      text-align center;
      padding: 10px 10px;
    }
  }

  &__reset {
    display none;
    flex-direction column;
    margin-top 15px;
    margin-left 5px;
    line-height 18px;
    padding: 10px 15px;
  }

  &__loading {
    height: 20px;
    margin-left: 8px;
    margin-top: 1px;

    &:after {
      content: url('/i/img/filter-loading.gif');
    }
  }
}

.filter-reminder-capture {
  font-size: 1.8rem;
  position: fixed;
  transform: translateX(-50%);
  text-align: center;
  left: 50%;
  top: 285px;
  display: flex;
  flex-direction: column;

  @media $handheld {
    font-size: 1.6rem;
  }

  &__arrows {
    position absolute;
    top 180px;
  }

  &__icon {
    width: 150px;
    transform: rotate(180deg);
    margin-left: 50px;
  }

  &__mask {
    display: none;
    width: 100%; height: 100%;
    background: rgba($white, .85);
    position: absolute;
    left: 0; top: 0;
    z-index: 10000;
  }

  &__box {
    margin-bottom: 5px;
  }

  &__text {
    display: inline-block;
    padding: 10px 20px 10px 55px;
    font-size: 1.8rem;
    position: relative;
    background: #f2f2f2;

    &:before {
      content: '';
      display: block;
      width: 28px; height: @width;
      border-radius: 50%;
      background: $blue url(/i/img/filter-remainder-capture-arrow.png) no-repeat 50%;
      position: absolute;
      left: 15px; top: 50%;
      transform: translateY(-50%);
    }
  }

  &__close {
    @extend .btn;
    bordered-btn(3px, $blue);
    background: transparent;
    line-height: 43px;
    border-radius: 30px;
    padding: 0 25px;
    font-size: 1.6rem;
    margin-top: 10px;
    z-index 1;

    &:hover {
      background: $white;
    }
  }
}