
/*   -=========== Infopage ===========-   */

.info-page--caption {
  color: #000;
  text-align: center;
  position: relative;
  margin-bottom: 35px;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 60%;
    transform: translateY(-50%);
  }

  .h1 {
    display: inline-block;
    background: $white;
    padding: 0 15px;
    position: relative;
  }
}

.two-halfs-section {
  display: flex;
  align-items: center;

  @media $mobile {
    flex-direction: column;
  }

  @media $not-mobile {
    > * + * {
      margin-left: 35px;
    }
  }
}

.two-halfs-section + .two-halfs-section {
  @media $not-mobile {
    margin-top: 50px;
  }

  @media $mobile {
    margin-top: 20px;
  }
}

.two-halfs-section--content,
.two-halfs-section--pic {
  flex: 1;
}

.two-halfs-section--pic {
  @media $mobile {
    .two-halfs-section_right & {
      order: 2;
    }
  }
}

.two-halfs-section--pic_full {
  position: relative;
  min-height: 396px;

  @media $mobile {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: none;

    .two-halfs-section_right & {
      right: 0;
    }

    .two-halfs-section_left & {
      left: 0;
    }
  }
}

.two-halfs-section--content {
  font-size: 1.8rem;

  @media $mobile {
    padding-top: 20px;
    padding-bottom: 20px;

    .two-halfs-section_right & {
      order: 1;
    }
  }

  * + * {
    margin-top: 1em;
  }

  li {
    list-style: none;
    padding-left: 28px;
    background: url(/i/mark-icon-dark.svg) no-repeat 0 .2em;
  }
}

.two-halfs-section--subcaption {
  padding-bottom: 1.5em;
  margin-bottom: 1.5em;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 63px; height: 2px;
    background: #bdbdbd;
    position: absolute;
    left: 0; top: 100%;
  }
}


.info-page--form {
  margin-top: 100px;
}

.info-page--form_contact {
  margin-top: 35px;
}


// Credit page

.credit-address {
  display: inline-block;
  text-align: left;
  padding-left: 75px;
  background: url(/i/discount-icon.svg) no-repeat 0 50%;
  font-size: 2.4rem;
  line-height: 1.35;
  color: #828282;
  margin-bottom: 45px;

  @media $small-mobile {
    font-size: 1.6rem;
  }

  span {
    display: block;
    color: #000;
  }
}


.info-page--section {
  padding-top: 35px;
  padding-bottom: 70px;
}

.info-page--section_blue {
  background: $blue;
}


.credit-features-caption {
  margin-bottom: 50px;
}

.credit-features {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: $white;
  font-size: 1.6rem;
  text-transform: uppercase;
  line-height: 1.5;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;


  @media $landscape-mobile {
    flex-direction: column;
    align-items: center;
  }

  .item {
    position: relative;

    @media $not-landscape-mobile {
      padding-bottom: 62px;
    }
  }

  .item--icon {
    width: 78px;

    @media $landscape-mobile {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 18px;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  for $icon in 1..4 {
    .item_{$icon} .item--icon {
      background: url('/i/credit-feature-' $icon '.svg') no-repeat 50%;
    }
  }

  .item--caption {
    text-align: center;
    white-space: nowrap;

    @media $not-landscape-mobile {
      position: absolute;
      bottom: 0; left: 50%;
      transform: translateX(-50%);
    }
  }

  .items-separator {
    flex: 1;
    height: 78px;
    position: relative;

    @media $not-landscape-mobile {
      &:before {
        content: '';
        display: block;
        width: 100%; height: 1px;
        background: rgba($white, .3);
        position: absolute;
        left: 0; top: 50%;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 9px; height: 9px;
      background: $white;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


.credit-table-caption {
  margin-bottom: 60px;
}

.credit-table-section {
  display: flex;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  @media $small-mobile {
    flex-direction: column;
  }

  ul {
    font-size: 1.4rem;
    color: #000;
  }

  li {
    line-height: 1.5;
    list-style: none;
    padding-left: 28px;
    background: url(/i/mark-icon-dark.svg) no-repeat 0 .2em;
  }

  li + li {
    margin-top: .5em;
  }
}

.credit-table-icon {
  padding-top: 150px;
  background: url(/i/credit-documents-icon.svg) no-repeat 50% 0;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
  color: #000;

  @media $not-small-mobile {
    margin-right: 40px;
  }

  @media $small-mobile {
    margin-bottom: 30px;
    font-size: 1.6rem;
  }
}

.credit-table {
  margin-bottom: 45px;
  font-size: 1.4rem;

  td, th {
    vertical-align: top;
    padding: 20px;

    @media $small-mobile {
      padding: 10px;
    }
  }

  th {
    color: #828282;
    text-align: left;
    font-weight: normal;
  }

  tr + tr {
    td, th {
      border-top: 2px solid #e0e0e0;
    }
  }

  td + td,
  th + td {
    border-left: 1px solid #e0e0e0;
  }

  @media $small-mobile {
    td {
      font-size: 1.2rem;
    }

    tr td:last-child {
      padding-right: 0;
    }

    th {
      padding-left: 0;
    }
  }
}


.credit-steps-caption {
  margin-bottom: 40px;
}

.credit-steps {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 1.5;

  @media $desktop {
    max-width: calc(100vw - 200px);
    margin-left: auto;
    margin-right: auto;
  }

  @media $handheld {
    flex-direction: column;
    align-items: center;
  }

  .item {
    position: relative;

    @media $desktop {
      margin-bottom: 100px;
    }
  }

  .item--icon {
    width: 78px;

    @media $handheld {
      margin-left: auto;
      margin-right: auto;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  for $icon in 1..5 {
    .item_{$icon} .item--icon {
      background: url('/i/step-' $icon '.svg') no-repeat 50%;
    }
  }

  .item--caption {
    text-align: center;
    white-space: nowrap;

    @media $desktop {
      position: absolute;
      top: 100%; left: 50%;
      transform: translateX(-50%);
    }

    margin-top: 18px;
  }

  .items-separator {
    flex: 1;
    height: 78px;
    position: relative;

    @media $desktop {
      &:before {
        content: '';
        display: block;
        width: 100%; height: 1px;
        background: rgba($white, .3);
        position: absolute;
        left: 0; top: 50%;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 9px; height: 9px;
      background: $white;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


.credit-contact-caption {
  margin-bottom: 50px;
}

.credit-contact--content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 1.5;

  @media $small-mobile {
    flex-direction: column;
  }
}

@media $small-mobile {
  .credit-contact--column {
    width: 100%;
  }
}

.credit-contact--column:first-child {
  flex: .5;

  ~ .credit-contact--column {
    flex: 1;
  }
}

.credit-contact--column + .credit-contact--column {
  @media $not-small-mobile {
    margin-left: 50px;
  }

  @media $small-mobile {
    margin-top: 30px;
  }
}

.credit-contact--phones {
  padding-left: 33px;
  background: url(/i/phone-icon.svg) no-repeat 0 .25em;
  font-size: 2.4rem;
  white-space: nowrap;
  margin-top: -.25em;
}

.credit-contact--address {
  padding-left: 40px;
  background: url(/i/pin-icon.svg) no-repeat;
}

.credit-contact--worktime {
  padding-left: 40px;
  padding-bottom: 1px;
  background: url(/i/time-icon-blue.svg) no-repeat 0 0 / 25px auto;
  margin-top: 2em;
}

.credit-contact--parking {
  padding-left: 47px;
  background: url(/i/parking-icon-blue.svg) no-repeat;

  p + p {
    margin-top: 1em;
  }
}

.credit-contact--messengers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 220px;
  margin-top: 25px;

  @media $small-mobile {
    margin-left: auto;
    margin-right: auto;
  }

  .item {
    display: block;
  }

  for $messenger in 'whatsapp' 'viber' 'telegram' {
    .item_{$messenger} {
      background: url('/i/' $messenger '.svg') no-repeat 50%;
      width: width('i/' + $messenger + '.svg'); height: height('i/' + $messenger + '.svg');
    }
  }
}
