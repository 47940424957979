/*   -=========== Profile authorazation ===========-   */

.auth-page {
  height: 100vh;
  font-family: "Didact Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;

  @media only screen and (max-width: 899px) {
    padding: 0;
  }

  .auth-page--wrapper {
    display: flex;
    flex-flow: row;
    height: 100%;
    min-width: 100%;

    @media only screen and (max-width: 899px) {
      display: block;
      height: calc(100% - 95px);
      padding-top: 95px;
    }
  }

  .header {
    @media only screen and (min-width: 900px) {
      display: none;
    }
  }

  .mobile-menu {
    top: 95px;
    padding-top: 15px;
  }

  .mobile-menu-fixed {
    display: none;

    @media only screen and (max-width: 1275px) and (min-width: 900px) {
      display: block;
    }
  }
}

.desktop-big {
  display: block;

  @media only screen and (max-width: 1275px) {
    display: none;
  }
}

.desktop-small {
  display: none;

  @media only screen and (max-width: 1275px) {
    display: block;
  }
}

.success-popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #0000004D;

  .success-popup--wrapper {
    margin: auto;
    margin-top: calc((100vh - 600px) / 2);
    width: 630px;
    height: 600px;
    background: url(/i/papper-bg.png) repeat center;
    background-size: 50%;

    @media only screen and (max-width: 1275px) {
      width: 440px;
      height: 420px;
      margin-top: calc((100vh - 420px) / 2);
    }

    @media only screen and (max-width: 899px) {
      width: 350px;
      height: 340px;
      margin-top: calc((100vh - 340px) / 2);
      border-radius: 30px;
    }

    .success-popup--close {
      width: 60px;
      height: 60px;
      float: right;
      margin: 20px 20px 0 0;
      cursor: pointer;

      @media only screen and (max-width: 1275px) {
        width: 50px;
        height: 50px;
        margin: 15px 15px 0 0;
      }

      @media only screen and (max-width: 899px) {
        width: 40px;
        height: 40px;
      }
    }

    .success-popup--text {
      float: right;
      width: 100%;
      margin-top: 20px;
      font-size: 42px;
      line-height: 48px;
      text-align: center;
      color: #4F4F4F;

      @media only screen and (max-width: 1275px) {
        margin-top: 5px;
        font-size: 28px;
        line-height: 30px;
      }

      @media only screen and (max-width: 899px) {
        width: 241px;
        margin: 15px 0 0 15px;
        float: left;
        font-size: 24px;
        line-height: 26px;
        text-align: left;

        br {
          display: none;
        }
      }
    }

    .success-popup--pic {
      width: 240px;
      height: 240px;
      margin-top: 40px;
      margin-left: calc((100% - 240px) / 2);

      @media only screen and (max-width: 1275px) {
        width: 180px;
        height: 180px;
        margin-top: 30px;
        margin-left: calc((100% - 180px) / 2);
      }

      @media only screen and (max-width: 899px) {
        width: 150px;
        height: 150px;
        margin-top: 20px;
        margin-left: calc((100% - 150px) / 2);
      }
    }
  }
}

.auth-page--text {
  color: #4F4F4F;
  font-size: 24px;
  line-height: 28px;
  text-align: left;

  @media only screen and (min-width: 1276px) and (max-height: 899px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (max-width: 1275px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media only screen and (max-width: 899px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.auth-page--description {
  flex: 1;
  height: 100%;
  background: url(/i/auth-bg.png) no-repeat center;
  background-size: cover;

  @media only screen and (max-width: 899px) {
    flex: none;
    height: 240px;
  }

  .auth-page--filter-color {
    background-color: #97CDFBEB;
    height: calc(100% - 140px);
    padding: 140px 0 0 160px;

    @media only screen and (max-height: 1079px) {
      height: calc(100% - 50px);
      padding-top: 50px;
    }

    @media only screen and (min-width: 1441px) {
      padding-left: calc((100vw - 1440px) / 2 + 160px);
    }

    @media only screen and (max-width: 1275px) {
      height: calc(100% - 84px);
      padding: 84px 0 0 47px;
    }

    @media only screen and (max-width: 899px) {
      background-color: #97CDFA;
      height: 206px;
      padding: 20px calc((100% - 390px) / 2 + 34px);
    }

    @media only screen and (max-width: 390px) {
      padding: 20px 34px;
    }
  }

  .auth-page--back-btn {
    width: 125px;
    height: 40px;
    color: $white;
    border: 1px solid $white;
    border-radius: 30px;
    background-color: transparent;
    font-size: 20px;
    text-align: center;
    cursor: pointer;

    .auth-page--back-btn-arrow {
      width: 15px;
      height: 15px;
      float: left;
      margin: 5px 0 0 7px;
      background: url(/i/arrow-left.png) no-repeat 100%;
      background-size: contain;
    }

    span {
      float: left;
      margin-left: 17px;
    }
  }

  .auth-page--header-content {
    display: flex;
    align-items: center;
    margin-top: 40px;

    @media only screen and (max-width: 1275px) {
      margin-top: 0;
    }

    @media only screen and (max-width: 899px) {
      display: none;
    }

    .auth-page--logo {
      display: inline-block;
      width: 140px;
      height: 140px;
      background: url(/i/oboima-logo-white.svg) no-repeat 50% / contain;

      @media only screen and (max-width: 1275px) {
        width: 120px;
        height: 120px;
      }
    }

    .auth-page--phones {
      font-size: 34px;
      margin-left: 40px;

      @media only screen and (max-width: 1275px) {
        font-size: 32px;
        line-height: 34px;
        margin-left: 30px;
      }

      a {
        color: $white;
      }
    }
  }

  .auth-page--caption {
    margin-top: 60px;
    color: #4F4F4F;
    font-size: 42px;
    line-height: 46px;
    text-align: left;

    @media only screen and (min-width: 1276px) and (max-height: 899px) {
      margin-top: 36px;
      font-size: 38px;
      line-height: 38px;
    }

    @media only screen and (max-width: 1275px) {
      margin-left: 36px;
      font-size: 28px;
      line-height: 30px;
    }

    @media only screen and (max-width: 899px) {
      margin: 0;
      font-size: 18px;
      line-height: 20px;
      text-align: center;

      br {
        display: none;
      }
    }
  }

  .auth-page--rectangle {
    width: 70px;
    height: 2px;
    background-color: #4F4F4F;
    margin-top: 20px;

    @media only screen and (max-width: 1275px) {
      width: 60px;
      margin: 15px 0 0 36px;
    }

    @media only screen and (max-width: 899px) {
      display: none;
    }
  }

  .auth-page--text-description {
    margin-top: 30px;

    @media only screen and (max-width: 1275px) {
      margin: 20px 0 0 36px;
    }

    @media only screen and (max-width: 899px) {
      margin: 0;
      text-align: center;

      br {
        display: none;
      }

      .mobile {
        display: block;
      }
    }

    p+p {
      margin-top: 25px;

      @media only screen and (max-width: 1275px) {
        margin-top: 20px;
      }

      @media only screen and (max-width: 899px) {
        margin-top: 10px;
      }
    }
  }
}

.auth-page--form {
  flex: 1;
  background: url(/i/papper-bg.png) repeat center;
  background-size: 50%;
  height: 100%;

  @media only screen and (max-width: 899px) {
    flex: none;
    height: calc(100% - 246px);
    min-height: fit-content;
    padding-bottom: 20px;
  }

  .auth-page--form-padding {
    padding: 140px 0 0 114px;

    @media only screen and (max-height: 1079px) {
      padding-top: 50px;
    }

    @media only screen and (max-width: 1275px) {
      padding: 84px 0 0 33px;
    }

    @media only screen and (max-width: 899px) {
      padding: 20px calc((100% - 390px) / 2 + 10px) 0;
    }

    @media only screen and (max-width: 390px) {
      padding: 20px 10px 0;
    }

    .auth-page--form-caption {
      color: #4F4F4F;
      font-size: 42px;
      line-height: 46px;
      text-align: left;

      @media only screen and (min-width: 1276px) and (max-height: 899px) {
        font-size: 34px;
        line-height: 38px;
      }

      @media only screen and (max-width: 1275px) {
        width: fit-content;
        margin-left: 15px;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
      }

      @media only screen and (max-width: 899px) {
        font-size: 24px;
        line-height: 26px;
      }
    }

    .auth-page--text-form {
      margin-top: 12px;
    }

    .auth-page--form-wrapper {
      display: flex;
      width: fit-content;
      height: fit-content;
      margin-top: 30px;
      align-items: center;

      @media only screen and (min-width: 1276px) and (max-height: 899px) {
        margin-top: 20px;
      }

      @media only screen and (max-width: 1275px) {
        margin-top: 20px;
      }

      @media only screen and (max-width: 899px) {
        margin-top: 15px !important;
      }

      .auth-page--form-label {
        width: 28px;
        height: 28px;
        background-color: $white;
        border: 1px solid #BDBDBD;
        border-radius: 15px;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        float: left;

        @media only screen and (max-width: 1275px) {
          width: 24px;
          height: 24px;
          font-size: 16px;
          line-height: 24px;
          margin-left: 39px;
        }

        @media only screen and (max-width: 899px) {
          margin-left: 20px;
        }
      }

      .auth-page--form-label+.auth-page--text {
        margin-left: 20px;
        float: left;

        @media only screen and (max-width: 899px) {
          margin-left: 15px;
        }
      }

      .auth-page--text {
        @media only screen and (max-width: 1275px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }

    .p-small {
      margin-top: 20px;

      @media only screen and (min-width: 1276px) and (max-height: 899px) {
        margin-top: 15px;
      }
    }

    .auth-page--form-email-wrapper {
      height: 50px;

      @media only screen and (max-width: 1275px) {
        height: 40px;
        padding-left: 15px;
      }

      @media only screen and (max-width: 899px) {
        height: fit-content;
        margin: 20px 20px 0;
        padding: 0;
      }

      .auth-form {
        max-width: 100%;
        height: 100%;

        .form-field {
          width: 335px;
          height: 50px;
          float: left;

          @media only screen and (max-width: 1275px) {
            width: 234px;
            height: 40px;
          }

          @media only screen and (max-width: 899px) {
            width: 330px;
            height: 40px;
            float: none;
          }

          input {
            width: 335px;
            height: 50px;

            @media only screen and (max-width: 1275px) {
              width: 234px;
              height: 40px;
              font-size: 16px;
            }

            @media only screen and (max-width: 899px) {
              width: 330px;
              height: 40px;
            }
          }
        }

        .submit-button {
          width: 137px;
          height: 50px;
          margin-left: 20px;
          float: left;
          background-color: #97CDFA;
          border-radius: 25px;
          color: $white;
          font-size: 20px;
          line-height: 22px;

          @media only screen and (max-width: 1275px) {
            width: 130px;
            height: 40px;
            font-size: 16px;
            line-height: 28px;
          }

          @media only screen and (max-width: 899px) {
            display: block;
            line-height: 18px;
            float: none;
            margin: auto;
            margin-top: 15px;
          }
        }
      }
    }

    .auth-page--form-email-errors {
      height: fit-content;
      margin-top: 15px;

      @media only screen and (max-width: 1275px) {
        margin: 10px 0 0 15px;
        font-size: 16px;
        line-height: 18px;
      }

      @media only screen and (max-width: 899px) {
        margin-left: 0;
        text-align: center;
      }
    }

    .for-mobile {
      display: none;
    }

    @media only screen and (max-width: 899px) {
      .for-mobile {
        display: block;
      }

      .for-desktop {
        display: none;
      }
    }

    .whatsapp {
      width: 240px;
      height: 80px;
      margin-left: 130px;

      @media only screen and (min-width: 1276px) and (max-height: 899px) {
        width: 167px;
        height: 56px;
        margin: 15px 0 0 124px;
      }

      @media only screen and (max-width: 1275px) {
        width: 167px;
        height: 56px;
        margin: 15px 0 0 124px;
      }

      @media only screen and (max-width: 899px) {
        display: block;
        width: 186px;
        height: 62px;
        margin: auto;
        margin-top: 15px;
      }
    }

    .auth-page--form-phone-wrapper {
      display: flex;
      align-items: center;
      margin: 22px 0 0 60px;

      @media only screen and (max-width: 1275px) {
        margin-top: 15px;
      }

      @media only screen and (max-width: 899px) {
        width: fit-content;
        margin: auto;
        margin-top: 10px;
      }

      img {
        width: 30px;
        height: 30px;
        float: left;

        @media only screen and (max-width: 1275px) {
          width: 25px;
          height: 25px;
        }
      }

      .auth-page--form-phone {
        float: left;
        margin-left: 15px;
        font-size: 34px;
        line-height: 34px;
        color: #4F4F4F;

        @media only screen and (max-width: 1275px) {
          margin-left: 16px;
          font-size: 28px;
          line-height: 30px;
        }
      }
    }
  }
}